// components/SignInPage.js
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate, Link,useLocation } from 'react-router-dom';
import { useUserContext } from '../services/UserProvider';
import { GoogleLogin } from '@react-oauth/google';
import { signInWithGoogle } from '../services/api';
import {log, error} from '../services/logger';
import logo from '../penneduk-logo.svg';


const SignInSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});


const SignInPage = () => {
    const { login, updateUserToken } = useUserContext();
    let navigate = useNavigate();
    const location = useLocation();
    const [signInError, setSignInError] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            log("Token found: ", token);
            updateUserToken(token); // Consider a more secure storage or handling mechanism
            log("Navigating to dashboard");
            navigate('/dashboard');
        }
    }, [location, navigate, updateUserToken]);
    
    const onGoogleLoginSuccess = async (response) => {
        try {
            const accessToken = await response.credential; // Get the access token from the response
            log('Google Access Token:', accessToken);
            
            // Pass the access token to your signInWithGoogle function
            const signInResponse = await signInWithGoogle(accessToken);
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get('token');

            if (token) {
                // Save the token to local storage or context
                //localStorage.setItem('jwtToken', token);
                log("Token found: ", token);
                updateUserToken(token);
                log("Navigating to dashboard");
                // Redirect or update UI accordingly
                navigate('/dashboard');
              }
         
        } catch (error) {
            // Handle error
            console.error('Error during Google sign-in:');
        }
    };
    
    const signInWithGoogle = async (accessToken) => {
        try {
            log("Initiating Google OAuth flow");
            // Redirect the user to the Google OAuth authorization endpoint
            //window.location.href = 'https://pennedserver-production.up.railway.app/api/auth/google';
            //window.location.href = 'http://localhost:3001/api/auth/google';
            window.location.href = 'https://api.penned.co/api/auth/google';
        } catch (error) {
            // Handle error
            console.error('Error initiating Google OAuth flow:', error);
        }
    };

    
    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card>
                        <Card.Body>
                            <Card.Img variant="top" style={{height: 100}} src={logo}/>
                            <Card.Title className="text-center mb-4">Sign In</Card.Title>
                            <Formik
                                initialValues={{ email: '', password: '' }}
                                validationSchema={SignInSchema}
                                onSubmit={async (values, { setSubmitting }) => {
                                    try {
                                      // Call login function (assumed to be part of authService)
                                      await login(values.email, values.password);
                                      
                                      // If no error is thrown, the account is active, proceed with login
                                      //localStorage.setItem('jwtToken', response.token); // Store JWT token
                                      navigate('/dashboard'); // Navigate to dashboard
                                  
                                    } catch (error) {
                                      console.error('Error during signin:');
                                  
                                      // Check if the error is a 403 response indicating inactive account
                                      if (error.response && error.response.status === 403) {
                                        //console.log('Setting sign-in error:', error.response.data.message);  // Add this log to check

                                        setSignInError(error.response.data.message || 'Your account is still pending approval. Please wait for admin approval.');
                                      } else {
                                        // Handle other errors (e.g., incorrect credentials, server errors)
                                        setSignInError('Failed to sign in. Check your credentials.');
                                      }
                                    } finally {
                                      setSubmitting(false); // Set form submitting status to false
                                    }
                                  }}                                  
                                
                            >
                                {({ errors, touched, handleSubmit, handleChange, values, isSubmitting }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                isValid={touched.email && !errors.email}
                                                isInvalid={touched.email && !!errors.email}
                                                placeholder="Enter email"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.email}
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                type="password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                isValid={touched.password && !errors.password}
                                                isInvalid={touched.password && !!errors.password}
                                                placeholder="Password"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        {signInError && <div className="text-danger mb-3">{signInError}</div>} {/* Display error message */}

                                        <Button variant="primary" type="submit" className="w-100" disabled={isSubmitting}>
                                            Submit
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                            <Col className="my-3">
                                <p className='mt-3'>Need an account? <Link to="/signup">Sign up here</Link>.</p>
                            </Col>
                            {/* <GoogleLogin
  onSuccess={onGoogleLoginSuccess}
  onError={() => console.log('Login Failed')}
/> */}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SignInPage;
