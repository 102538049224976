import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CloseButton, Col, Form, Row } from 'react-bootstrap';
import { log } from '../services/logger';
import { createRecipient, getRecipient, updateRecipient } from '../services/api';

function isoToDate(isoString) {
    const date = new Date(isoString);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    // Format the date as YYYY-MM-DD
    return `${year}-${month}-${day}`;
}

function RecipientInputForm({ selectedCampaign, setRefreshRecipients, customVariables, recipientIdForEdit, onClear, onClose }) {
    const emptyFormData = {
        title: '',
        firstName: '',
        lastName: '',
        companyName: '',
        addressLine1: '',
        addressLine2: '', // Initialize with empty string
        addressLine3: '',
        postcode: '',
        cityCounty: '',
        requestedPostDate: '', // Initialize with empty string or a placeholder date
        country: '',
        selectedCampaign: selectedCampaign,
        customVariables: [],
        userId: '' // This could be set initially if you have access to userId upfront
    }

    const initialCustomVariableMap = []
    customVariables.map(v => initialCustomVariableMap.push({ "name": v, "value": '' }));

    const [formData, setFormData] = useState(emptyFormData);
    const [customVariableMap, setCustomVariableMap] = useState(initialCustomVariableMap);
    const [submissionState, setSubmissionState] = useState('');
    const [isEditing, setIsEditing] = useState((recipientIdForEdit))

    useEffect(() => {
        if (isEditing) {
            // Fetch the recipient data using recipientIdForEdit
            const fetchRecipientData = async () => {
                try {
                    log("fetching recipient");
                    const response = await getRecipient(recipientIdForEdit);
                    const recipientData = response.data;
                    log(recipientData);
                    // Populate the form
                    const storedCustomVariables = recipientData.customVariables || []; // Safely handle if customVariables are undefined

                    setCustomVariableMap(initialCustomVariableMap.map(variableEntry => {
                        return { "name": variableEntry.name, "value": storedCustomVariables.find(storedEntry => storedEntry.name === variableEntry.name)?.value || '' }
                    }));

                    setFormData({
                        ...formData,
                        title: recipientData.title,
                        firstName: recipientData.firstName,
                        lastName: recipientData.lastName,
                        companyName: recipientData.companyName,
                        addressLine1: recipientData.addressLine1,
                        addressLine2: recipientData.addressLine2,
                        addressLine3: recipientData.addressLine3,
                        postcode: recipientData.postcode,
                        cityCounty: recipientData.cityCounty,
                        requestedPostDate: isoToDate(recipientData.requestedPostDate),
                        country: recipientData.country,
                        customVariables: customVariableMap
                        // ... set other fields
                    });
                } catch (error) {
                    console.error('Error fetching recipient data:', error);
                }
            };

            fetchRecipientData();
        }
    }, [isEditing]);

    
    useEffect(() => {
        // Update formData whenever customVariableMap changes
        setFormData((prevFormData) => ({
            ...prevFormData,
            customVariables: customVariableMap
        }));
    }, [customVariableMap]); // Dependency on customVariableMap
    

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
        setSubmissionState("");
    };

    const handleAddRecipient = (event) => {
        event.preventDefault();
        // Logic to handle form submission
        try {
            if (isEditing) {
                //console.log("updating recipient", recipientIdForEdit, formData)
                updateRecipient(recipientIdForEdit, formData).then(resp => {
                    clearForm();
                    setRefreshRecipients(true);
                    setSubmissionState("Updated!");
                })
                closeForm();


            } else {
                //console.log("submitting new recipient", formData);
                createRecipient(formData).then(response => {
                    log(response.data);
                    clearForm();
                    setRefreshRecipients(true);
                    setSubmissionState("Added!");
                });
            }
        } catch (error) {
            //console.log(error);
        }
    };

    const clearForm = () => {
        setCustomVariableMap(initialCustomVariableMap);
        setFormData(emptyFormData);
        setIsEditing(false);
        onClear();
    };

    const closeForm = () => {
        clearForm();
        onClose();
    }

    // Custom Variable Logic:

    const handleCustomVariableChange = (index, event) => {
        setCustomVariableMap((prev) => {
            const temp = [...prev];
            temp[index] = { ...temp[index], "value": event.target.value };
            return temp;
        })
        setFormData({
            ...formData,
            customVariables: customVariableMap
        });
        log(formData);
    };

    return (
        <Card className='mb-3'>
            <CardBody>
                <Form className="mt-2" onSubmit={handleAddRecipient}>
                    <Row>
                        <Col lg={4}>
                            <Form.Group controlId="requestedPostDate">
                                <Form.Label style={{ fontWeight: 'bold' }}>When would you want this letter to be sent (day of being placed in the mail).</Form.Label>
                                <Form.Control
                                    type="date"
                                    value={formData.requestedPostDate}
                                    onChange={handleInputChange}
                                    style={{ startDate: '-2d' }}
                                    required />
                            </Form.Group>
                        </Col>
                        <Col>
                            <CloseButton className='float-end' onClick={() => closeForm()} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <Form.Group controlId="title">
                                {/* <Form.Label>Title</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    placeholder="Enter title"
                                    value={formData.title}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group controlId="firstName">
                                {/* <Form.Label>First Name</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter first name"
                                    value={formData.firstName}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group controlId="lastName">
                                {/* <Form.Label>Last Name</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter last name"
                                    value={formData.lastName}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <Form.Group controlId="companyName">
                                {/* <Form.Label>Company Name</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter company name"
                                    value={formData.companyName}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group controlId="addressLine1">
                                {/* <Form.Label>Address Line 1</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter address line 1"
                                    value={formData.addressLine1}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group controlId="addressLine2">
                                {/* <Form.Label>Address Line 2</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter address line 2"
                                    value={formData.addressLine2}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <Form.Group controlId="addressLine3">
                                {/* <Form.Label>Address Line 3</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter address line 3"
                                    value={formData.addressLine3}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group controlId="postcode">
                                {/* <Form.Label>Postcode</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter postcode"
                                    value={formData.postcode}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col lg={4}>
                            <Form.Group controlId="cityCounty">
                                {/* <Form.Label>City or Suburb</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter city or suburb"
                                    value={formData.cityCounty}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4}>
                            <Form.Group controlId="country">
                                {/* <Form.Label>Country</Form.Label> */}
                                <Form.Control type="text"
                                    placeholder="Enter country"
                                    value={formData.country}
                                    onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Custom Variable Data */}
                    <Row>
                        <Col>
                            <p>Custom Variables</p>
                            {customVariableMap.map((variable, index) => (
                                <Row key={index}>
                                    <Col>
                                        <Form.Label>{variable.name}</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            as="textarea"
                                            placeholder={variable.name}
                                            name="value"
                                            value={variable.value}
                                            onChange={(e) => handleCustomVariableChange(index, e)}
                                        />
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {submissionState}
                        </Col>
                        <Col style={{ "textAlign": "right" }} className='mx-2 mt-2'>
                            {!isEditing &&
                                <Button variant="secondary" className="mx-2" onClick={() => clearForm()}>
                                    Clear Form
                                </Button>
                            }
                            <Button variant="primary" className="mx-2" type="submit">
                                {isEditing ? "Update Recipient" : "Add Recipient"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </CardBody>
        </Card >
    )
}

export default RecipientInputForm;