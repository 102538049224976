import React from 'react';
import { Container, Row, Col, Card,Navbar,Nav } from 'react-bootstrap';
import LogoImage from '../assets/Logo-LargeSV.png'; // Adjust the path as necessary

const PendingApprovalPage = () => {
  return (
    <Container className="mt-5">
      
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title className="text-center mb-4">Account Under Review</Card.Title>
              <p className="text-center">Your account is being reviewed by our team and will be activated within 24 hours. You'll receive an email once your account is approved.</p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PendingApprovalPage;
