import React, { useEffect, useState } from 'react';
import { getAssignedMessages, getMessage, getRecipient, listSignatures } from '../services/api';
import '../styles/CreateMessage.css';

const PreviewMessage = ({ recipientId }) => {
    const [messageContent, setMessageContent] = useState('');
    const [recipient, setRecipient] = useState(null);
    const [fontStyle, setFontStyle] = useState('PremiumUltra23');
    const [missingVariables, setMissingVariables] = useState([]);
    const [signatures, setSignatures] = useState([]);

    useEffect(() => {
        const fetchSignatures = async () => {
            try {
                const signatureResponse = await listSignatures();
                setSignatures(signatureResponse.data || []);
                console.log(signatureResponse);
                
            } catch (error) {
                console.error('Error fetching signatures');
            }
        };
    
        fetchSignatures();
    }, []);
    
    useEffect(() => {
        // if (signatures.length === 0) {
        //     return;
        // }
    
        const fetchMessageContent = async () => {
            try {
                // Clear previous data before fetching new ones
                setRecipient(null);
                setMessageContent('');
                setMissingVariables([]);
                
                // Fetch recipient data
                const recipientResponse = await getRecipient(recipientId);
                const recipientData = recipientResponse.data;
                setRecipient(recipientData);
    
                // Fetch messages assigned to the campaign
                const assignedMessageResponse = await getAssignedMessages(recipientData.campaignId);
                const messageId = assignedMessageResponse.data.find(
                    assignedMessage => assignedMessage.campaignId === recipientData.campaignId
                )?.messageId;
    
                if (messageId) {
                    // Fetch the message content
                    const messageResponse = await getMessage(messageId);
                    const messageContent = messageResponse.data.messageContent || '';
    
                    // Set the font style based on the robotStyle from the message data
                    switch (messageResponse.data.robotStyle) {
                        case 'Style 1':
                            setFontStyle('PremiumUltra23');
                            break;
                        case 'Style 2':
                            setFontStyle('PremiumUltra27');
                            break;
                        case 'Style 3':
                            setFontStyle('PremiumUltra52');
                            break;
                        case 'Style 4':
                            setFontStyle('PremiumUltra66');
                            break;
                        case 'Style 5':
                            setFontStyle('PremiumUltra11');
                            break;
                        default:
                            setFontStyle('PremiumUltra23.ttf');
                            break;
                    }
    
                    // Replace variables with recipient data and track missing variables
                    const { content: personalizedContent, missing } = fillMessageVariables(messageContent, recipientData);
                    setMessageContent(personalizedContent);
                    setMissingVariables(missing);
                } else {
                    console.warn('No message ID found for the given campaign.');
                    setMessageContent('No message content available for this recipient.');
                }
            } catch (error) {
                console.error('Error fetching message or recipient data');
                setMessageContent('Error loading message content.');
            }
        };
    
        fetchMessageContent();
    }, [recipientId, signatures]);
    
    


    const normalizeStrings = (string) => {
        return string.toLowerCase().replace(/\s+|_/g, '');
    };

    const preprocessMessageContent = (content) => {
        let processedContent = content.replace(/^<div[^>]*>|<\/div>$/g, '');
        processedContent = processedContent.replace(/<p>/g, '').replace(/<\/p>/g, '<br>');
        return processedContent;
    };

    const renderMessageContent = () => {
        return (
            <div
                style={{ whiteSpace: 'pre-wrap', lineHeight: '1.5', fontSize: '22px', fontFamily: fontStyle }}
                dangerouslySetInnerHTML={{
                    __html: preprocessMessageContent(messageContent),
                }}
            />
        );
    };
   const fillMessageVariables = (content = '', recipient) => {
    if (typeof content !== 'string') {
        console.error('Invalid message content');
        return { content: '', missing: [] };
    }

    const missing = [];

    // Create a lookup object for all recipient fields (including customVariables)
    const variableLookup = {
        ...Object.fromEntries(
            Object.entries(recipient).map(([key, value]) => [normalizeStrings(key), value])
        ),
        ...Object.fromEntries(
            (recipient.customVariables || []).map(cv => [normalizeStrings(cv.name), cv.value])
        )
    };

    // Regex to find all variables with optional [[\r]] formatting
    const personalizedContent = content.replace(/{{(\[\[\\r\]\])*([^}]+?)(\[\[\\r\]\])*}}/g, (match, before, variable, after) => {
        const cleanedVariable = normalizeStrings(variable.trim());
        const value = variableLookup[cleanedVariable];

        if (value === undefined) {
            missing.push(variable);
        }

        let formattedValue = value !== undefined ? value : `{{${variable}}}`;
        if (before) {
            formattedValue = `\n${formattedValue}`;
        }
        if (after) {
            formattedValue = `${formattedValue}\n`;
        }

        return formattedValue;
    });

    // Replace signature placeholders with actual signatures, ensuring left alignment
    const contentWithSignatures = personalizedContent.replace(/{signature:\s*(.*?)\s*}/g, (match, signatureName) => {
        const cleanedSignatureName = normalizeStrings(signatureName.trim());
        const matchedSignature = signatures.find(sig => normalizeStrings(sig.signatureName) === cleanedSignatureName);
    
        if (matchedSignature) {
            const signatureHtml = matchedSignature.rawSig;
            return `
                <div style="
                    display: flex; 
                    justify-content: flex-start; 
                    align-items: center; 
                    margin: 0; /* Adjust margin to 0 */
                    padding: 0; /* Adjust padding to 0 */
                    max-height: 60px; 
                    overflow: hidden;
                    white-space: pre-line; /* Override to reduce extra spaces */
                ">
                    <img 
                        src="data:image/svg+xml;base64,${btoa(signatureHtml)}" 
                        alt="${signatureName} signature"
                        style="
                            max-height: 60px; 
                            width: auto; 
                            height: 100%; 
                            object-fit: contain; 
                            margin: 0; /* Ensure no margin on the image */
                            display: block;
                        "
                    />
                </div>`;
        } else {
            missing.push(`signature: ${signatureName}`);
            return `<div>{signature: ${signatureName}}</div>`;
        }
    });
    

    return { content: contentWithSignatures, missing };
};


    return (
        <div>
            {missingVariables.length > 0 && (
                <div className="alert alert-warning">
                    Your message has variables with no data found in the recipient: {missingVariables.join(', ')}
                </div>
            )}
             {signatures.length > 0 && (
                <div className="alert alert-info">
                    Signatures displayed may have additional spacing before or after. Signatures are manually fixed to position at time of writing and those spaces will be removed.
                </div>
            )}
            <p>
                {renderMessageContent()}
            </p>
        </div>
    );
};

export default PreviewMessage;
