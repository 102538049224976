import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {log, error} from './logger';

const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
      url = 'https://api.penned.co';
      break;
    case 'development':
    default:
      url = 'http://localhost:3001'
  }

  return url;
}

const API_URL = `${getBaseUrl()}/api/auth`;

// // A helper function to make POST requests
// const post = (path, data) => axios.post(`${API_URL}/${path}`, data);
// A helper function to make POST requests
// A helper function to make POST requests
const post = (path, data) => {
  return axios.post(`${API_URL}/${path}`, data) // Ensure the return statement is present
    .then(response => {
      //console.log('Axios Response:', response); // Debug log for response
      return response; // Ensure that the actual response is returned
    })
    .catch(error => {
      console.error('Axios Error:', error.response || error); // Handle error response properly
      throw error; // Ensure error is thrown and caught
    });
};


log("API URL: ", API_URL);
// Set up Axios to automatically send the token on all requests
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
log("Attempting sign/signup");
  return config;
});

// Response interceptor to handle token refresh logic
axios.interceptors.response.use(response => response, async error => {
  const originalRequest = error.config;
  // Check for 401 Unauthorized response and ensure it's not a retry
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true; // mark it so that we don't get into an infinite loop
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      // Attempt to get a new token
      const res = await axios.post(`${API_URL}/refresh`, { refreshToken });
      const { token, refreshToken: newRefreshToken } = res.data;
      // Update tokens in local storage
      localStorage.setItem('jwtToken', token);
      if (newRefreshToken) {
        localStorage.setItem('refreshToken', newRefreshToken);
      }
      // Update the token in the header for the original request
      originalRequest.headers['Authorization'] = `Bearer ${token}`;
      // Retry the original request with the new token
      return axios(originalRequest);
    } catch (refreshError) {
      console.error('Error refreshing token:', refreshError);
      // Perform logout or token cleanup
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('refreshToken');
      // Redirect to login or show a modal asking the user to re-login might be necessary here
      return Promise.reject(refreshError);
    }
  }
  // Return any error which is not due to token expiration
  return Promise.reject(error);
});

const authService = {
  
  // Function to handle user signup
  signup: (email, password, location, ipAddress) => post('signup', { email, password, location, ipAddress }),

  // Function to handle user signin - Ensure this points to the 'signin' endpoint
  // signin: (email, password) => post('signin', { email, password }),
  signin: (email, password) => {
    // Ensure axios.post is returning the promise and response
    return post(`signin`, { email, password });
  },
  

  // Optionally, add a function for signout if there are tasks to perform on the server-side
  signout: () => {
    // Perform any server-side cleanup if needed
    localStorage.removeItem('jwtToken'); // Remove the token
    localStorage.removeItem('refreshToken');

    //return axios.post(`${API_URL}signout`);
  },

  refreshToken: () => {
    const refreshToken = localStorage.getItem('refreshToken');
    return axios.post(`${API_URL}/refresh`, { refreshToken })
      .then(response => {
        const { token, refreshToken } = response.data;
        localStorage.setItem('jwtToken', token);
        if (refreshToken) { 
          localStorage.setItem('refreshToken', refreshToken);
        }
        return token; 
      });
  },
};

export default authService;
