import { Container, Row, Col, Form, Button, Card, Table, CardText } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';  // Add PapaParse for CSV parsing
import { createImportTemplate, fetchImportTemplates, deleteImportTemplate, updateImportTemplate } from '../services/api'; // Add update API call
import { defaultRecipientFields } from '../constants'; // Your default recipient fields

const ImportTemplatesPage = () => {
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [fieldMappings, setFieldMappings] = useState({});
  const [importTemplates, setImportTemplates] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [csvFirstRow, setCsvFirstRow] = useState([]); // State for first row data
  const [editingTemplateId, setEditingTemplateId] = useState(null); // Track editing state
  const [expanded, setExpanded] = useState(false); // For collapsing card content

  useEffect(() => {
    // Fetch existing import templates on page load
    fetchImportTemplates()
      .then(response => {
        setImportTemplates(response.data);
      })
      .catch(error => {
        console.error('Error fetching import templates:', error);
      });
  }, []);

  // Function to toggle expanded state
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  // Function to handle CSV upload and preview headers
  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target.result;

      // Parse CSV and get headers and first row using PapaParse
      Papa.parse(csvData, {
        header: true,  // Extract headers
        skipEmptyLines: true, // Skip empty lines
        complete: (result) => {
          const headers = result.meta.fields;  // Get the headers
          const firstRow = result.data[0];  // Get the first row of data
          setCsvHeaders(headers);  // Set headers in state
          setCsvFirstRow(firstRow); // Set the first row data in state
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
        }
      });
    };
    reader.readAsText(file);  // Read file as text
  };

  // Handle field mappings change
  const handleMappingChange = (csvHeader, recipientField) => {
    if (Object.values(fieldMappings).includes(recipientField)) {
      alert(`${recipientField} is already selected. Please choose another field.`);
      return;
    }

    setFieldMappings({ ...fieldMappings, [csvHeader]: recipientField });
  };

  // Handle clearing the CSV upload and field mappings
  const handleClear = () => {
    setCsvFile(null);
    setCsvHeaders([]);
    setCsvFirstRow([]);
    setFieldMappings({});
    setTemplateName('');
    setEditingTemplateId(null);  // Reset editing state
  };

  // Handle saving the import template (new or edited)
  const handleSaveTemplate = async () => {
    if (!templateName || !csvHeaders.length) {
      alert('Please provide a template name and upload a CSV.');
      return;
    }

    const templateData = {
      templateName,
      csvHeaders,
      fieldMappings: Object.keys(fieldMappings).map((csvHeader) => ({
        csvHeader,
        recipientField: fieldMappings[csvHeader],
      })),
    };

    try {
      if (editingTemplateId) {
        // Update existing template
        await updateImportTemplate(editingTemplateId, templateData);
        alert('Template updated successfully');
      } else {
        // Create new template
        await createImportTemplate(templateData);
        alert('Template saved successfully');
      }
      
      handleClear(); // Clear the fields after saving or updating

      // Re-fetch the updated list of templates
      const updatedTemplates = await fetchImportTemplates();
      setImportTemplates(updatedTemplates.data);

    } catch (error) {
      console.error('Error saving import template:', error);
      alert('Error saving template.');
    }
  };

  // Handle deleting an import template
  const handleDeleteTemplate = async (templateId) => {
    if (window.confirm('Are you sure you want to delete this template?')) {
      try {
        await deleteImportTemplate(templateId);
        alert('Template deleted successfully');
        fetchImportTemplates().then(setImportTemplates); // Refresh the template list after deletion
      } catch (error) {
        console.error('Error deleting import template:', error);
        alert('Error deleting template.');
      }
    }
  };

  // Handle editing a template
  const handleEditTemplate = (template) => {
    setTemplateName(template.templateName);
    setCsvHeaders(template.csvHeaders);
    setFieldMappings(
      template.fieldMappings.reduce((acc, mapping) => {
        acc[mapping.csvHeader] = mapping.recipientField;
        return acc;
      }, {})
    );
    setEditingTemplateId(template._id); // Set the template ID to indicate we're editing
  };

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <h1>Import Templates</h1>
          {/* Collapsible Card */}
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>How do Import Templates work?</Card.Title>
              <CardText>
                Import Templates map your CSV files to our required recipient structure.
                You can use a provided CSV template or map your own CSV file to our database. 
                <span className={expanded ? 'd-none' : ''}>... </span>
                {expanded && (
                  <>
                    Once you create a template and use it to add recipients on a recurring basis, 
                    we'll automatically identify the mandatory/requested fields and map them to your designated headers.
                    <p></p>
                    <p><strong>We have the following standard fields:</strong></p>
                    <ul>
                      <li>title - e.g "Mr or Mrs"</li>
                      <li>firstName</li>
                      <li>lastName</li>
                      <li>fullName</li>
                      <li>companyName</li>
                      <li>addressLine1</li>
                      <li>addressLine2</li>
                      <li>addressLine3</li>
                      <li>cityCounty</li>
                      <li>postcode</li>
                      <li>country</li>
                      <li>requestedPostDate</li>
                    </ul>
                    <p><strong>Penned's recipient structure will store any information you pass outside of the standard structure in our customVariables object. </strong></p>
                    <p>If you don't have a <code>requestedPostDate</code>, we'll automatically set one for seven days from the upload date.</p>
                    <p>And if you don't have all the fields, that's perfectly fine! Map the available fields as best as you can, and we'll handle the rest.</p>
                  </>
                )}
              </CardText>
              <Button variant="link" onClick={toggleExpand}>
                {expanded ? 'Show Less ▲' : 'Show More ▼'}
              </Button>
            </Card.Body>
          </Card>

          {/* Rest of the page content */}
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{editingTemplateId ? 'Edit Import Template' : 'Create a New Import Template'}</Card.Title>

              <Form.Group controlId="templateName" className="my-3">
                <Form.Label>Template Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter template name"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </Form.Group>

              <Form.Group controlId="csvFileUpload" className="my-3">
                <Form.Label>Upload CSV File</Form.Label>
                <Form.Control type="file" onChange={handleCSVUpload} />
              </Form.Group>

              {csvHeaders.length > 0 && (
                <>
                  <Card className="mt-4">
                    <Card.Body>
                      <Card.Title>Map CSV Headers to Recipient Fields</Card.Title>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>CSV Header</th>
                            <th>Example Data</th>
                            <th>Map to Recipient Field</th>
                          </tr>
                        </thead>
                        <tbody>
                          {csvHeaders.map((header) => (
                            <tr key={header}>
                              <td>{header}</td>
                              <td><em>{csvFirstRow ? csvFirstRow[header] : ''}</em></td>
                              <td>
                                <Form.Control
                                  as="select"
                                  value={fieldMappings[header] || ''}
                                  onChange={(e) => handleMappingChange(header, e.target.value)}
                                >
                                  <option value="">Select Field</option>
                                  {defaultRecipientFields.map((field) => (
                                    <option
                                      key={field}
                                      value={field}
                                      disabled={Object.values(fieldMappings).includes(field)}
                                    >
                                      {field}
                                    </option>
                                  ))}
                                </Form.Control>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>

                  <Button
                    className="mt-3"
                    variant="primary"
                    onClick={handleSaveTemplate}
                  >
                    {editingTemplateId ? 'Update Template' : 'Save Template'}
                  </Button>

                  <Button
                    className="mt-3 ml-3"
                    variant="secondary"
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                </>
              )}
            </Card.Body>
          </Card>

          {/* Render existing templates */}
          <Card className="mt-4">
            <Card.Body>
              <Card.Title>Saved Import Templates</Card.Title>
              {importTemplates.length > 0 ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Template Name</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importTemplates.map((template) => (
                      <tr key={template._id}>
                        <td>{template.templateName}</td>
                        <td>
                          <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => handleEditTemplate(template)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => handleDeleteTemplate(template._id)}
                            style={{ marginLeft: '10px' }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No templates found</p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ImportTemplatesPage;
