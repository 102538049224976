import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getMessage, updateMessage } from '../services/api';
import { Button, Row, Alert } from 'react-bootstrap';
import '../styles/A4Editor.css';

const TEMPLATE_SIZES = {
    "A4 Letter": { width: 210, height: 297 },
    "A5 - Portrait": { width: 559, height: 794 },
    "A5 - Landscape": { width: 794, height: 559 },
    "A6 - Portrait": { width: 397, height: 559 },
    "A6 - Landscape": { width: 559, height: 397 },
    "DL Complement": { width: 312, height: 624 }
};

const A4Editor = () => {
    const { campaignId, recipientId, messageId } = useParams();
    const navigate = useNavigate();

    const messageContentRef = useRef('');
    const messageContentRightRef = useRef('Right Aligned Text');
    const [fontStyle, setFontStyle] = useState('PremiumUltra23');
    const [fontSize, setFontSize] = useState('20px');
    const [selectedTemplate, setSelectedTemplate] = useState("A4 Letter");
    const [messageName, setMessageName] = useState('');
    const [robotStyle, setRobotStyle] = useState('');
    const [penSize, setPenSize] = useState('');
    const [otherParams, setOtherParams] = useState({});

    const leftTextRef = useRef(null);
    const rightTextRef = useRef(null);

    useEffect(() => {
        console.log('Current margins:', otherParams);
    }, [otherParams]);
    
    useEffect(() => {
        const loadMessageData = async () => {
            try {
                const messageResponse = await getMessage(messageId);
                const data = messageResponse.data;
    
                messageContentRef.current = data.messageContent || '';
                messageContentRightRef.current = data.messageContentRight || 'Right Aligned Text';
                setMessageName(data.messageName);
                setRobotStyle(data.robotStyle);
                setPenSize(data.penSize || '');
                setFontSize(data.penSize);
                setOtherParams({
                    paperSize: data.paperSize || 'A4',
                    marginLeft: data.marginLeft || '20',
                    marginRight: data.marginRight || '20',
                    marginTop: data.marginTop || '20',
                    marginBottom: data.marginBottom || '20',
                });
                
    
                const style = data.robotStyle || 'Style 1';
                handleRobotStyleChange(style);
            } catch (error) {
                console.error('Error loading message data:', error);
            }
        };
    
        loadMessageData();
    }, [messageId]);
    

    const handleRobotStyleChange = (style) => {
        const styles = {
            'Style 1': 'PremiumUltra23',
            'Style 2': 'PremiumUltra27',
            'Style 3': 'PremiumUltra52',
            'Style 4': 'PremiumUltra66',
            'Style 5': 'PremiumUltra11'
        };
        setFontStyle(styles[style] || 'PremiumUltra23');
    };

    const handleBlur = (ref, isRight = false) => {
        if (isRight) {
            messageContentRightRef.current = ref.current.innerText; // Capture as raw text with line breaks
        } else {
            messageContentRef.current = ref.current.innerText; // Capture as raw text with line breaks
        }
    };

    const sanitizedMargins = {
        marginLeft: parseInt((otherParams.marginLeft?.split('|')[0]) || 0, 10),
        marginRight: parseInt((otherParams.marginRight?.split('|')[0]) || 0, 10),
        marginTop: parseInt((otherParams.marginTop?.split('|')[0]) || 0, 10),
        marginBottom: parseInt(otherParams.marginBottom || 0, 10),
    };
    
    
    
    const handleSave = async () => {
        try {
            await updateMessage(messageId, {
                messageContent: messageContentRef.current,
                messageContentRight: messageContentRightRef.current === 'Right Aligned Text' ? '' : messageContentRightRef.current,
                messageName,
                robotStyle,
                penSize,
                marginLeft: otherParams.marginLeft,
                marginRight: otherParams.marginRight,
                marginTop: otherParams.marginTop,
                marginBottom: otherParams.marginBottom,
                ...otherParams,
            });
            
            alert('Message saved successfully.');
        } catch (error) {
            console.error('Error saving message:', error);
        }
    };
    
    
    const handleKeyDown = (e) => {
        // Prevent default behavior of adding <div> on Enter
        if (e.key === 'Enter') {
            e.preventDefault();
            document.execCommand('insertHTML', false, '<br><br>'); // Inserts two <br> for spacing
        }
    };
    

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', // Centers content horizontally
                justifyContent: 'center', // Centers content vertically if needed
                padding: '20px',
            }}
        >
            <div style={{ maxWidth: '800px', textAlign: 'center' }}>
                <Button variant="primary" onClick={handleSave} style={{ marginBottom: '20px' }}>
                    Save
                </Button>{' '}
                <Button
                    variant="secondary"
                    onClick={() => navigate(`/dashboard/messages/edit/${messageId}`)}
                    style={{ marginBottom: '20px' }}
                >
                    Back to Messages
                </Button>
                <h2>A4 Message Editor {recipientId}</h2>
                <Row>
                    <Alert
                        variant="success"
                        style={{
                            margin: '10px auto', // Centers the alert horizontally
                            maxWidth: '600px', // Limits the width of the alert
                            textAlign: 'left', // Aligns text to the left
                        }}
                    >
                        This page allows you to edit and create text aligned to the right on your A4 letter, as well as edit the left-hand content. Please note that all templates are previewed before writing to ensure they fit your letterhead. Any letters that exceed the recommended page length will be flagged manually as a new page. This will not display as seperate pages on your message previews.
                    </Alert>
                </Row>
            </div>
            <h4>Click on the text placeholders to edit the content.</h4>
            <div
                className="template-preview"
                style={{
                    width: `${TEMPLATE_SIZES[selectedTemplate].width}mm`,
                    height: `${TEMPLATE_SIZES[selectedTemplate].height}mm`,
                    position: 'relative',
                    border: '1px solid #000',
                    backgroundColor: '#fff',
                    margin: '20px auto', // Centers the preview
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Adds subtle shadow
                }}
            >
                <div
                    className="editable-text"
                    ref={leftTextRef}
                    style={{
                        '--fontStyle': fontStyle,
                        '--fontSize': fontSize,
                        marginLeft: `${sanitizedMargins.marginLeft}mm`,
                        marginRight: `${sanitizedMargins.marginRight}mm`,
                        marginTop: `${sanitizedMargins.marginTop}mm`,
                        marginBottom: `${sanitizedMargins.marginBottom}mm`,
                    }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={() => handleBlur(leftTextRef)}
                    onKeyDown={handleKeyDown}
                    dangerouslySetInnerHTML={{ __html: messageContentRef.current }}
                />
                <div
                    className="editable-text right-text"
                    ref={rightTextRef}
                    style={{
                        '--fontStyle': fontStyle,
                        '--fontSize': fontSize,
                        marginTop: `${sanitizedMargins.marginTop}mm`,
                    }}
                    contentEditable
                    suppressContentEditableWarning
                    onBlur={() => handleBlur(rightTextRef, true)}
                    onKeyDown={handleKeyDown}
                    dangerouslySetInnerHTML={{ __html: messageContentRightRef.current }}
                />
            </div>
        </div>
    );
    
};

export default A4Editor;
