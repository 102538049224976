import React from 'react';
import { ProgressBar } from "react-bootstrap";

const RecipientProgressBar = ({ recipientData }) => {
    const getStatusDistribution = () => {
        const distribution = recipientData.reduce((counts, cur) => {
            counts[cur.statusV1]++;
            // Check if the recipient has a 'response' custom variable set to 'true'
            const hasResponse = cur.customVariables.some(
                (cv) => cv.name === 'response' && cv.value === 'true'
            );
            if (hasResponse) {
                counts.response++;
            }
            return counts;
        }, { new: 0, posted: 0, 'missing-information': 0, response: 0 });
        return distribution;
    };

    const getRelativeProgress = () => {
        const dist = getStatusDistribution();
        const total = recipientData.length;
        Object.keys(dist).forEach((key) => {
            dist[key] = (dist[key] / total) * 100;
        });
        return dist;
    };

    const progress = getStatusDistribution();
    const relativeProgress = getRelativeProgress();

    return (
        <ProgressBar style={{ height: '100%' }}>
            <ProgressBar variant="primary" now={relativeProgress.posted} label={`${progress.posted} posted`} />
            <ProgressBar variant="danger" now={relativeProgress["missing-information"]} label={`${progress["missing-information"]} missing info`} />
            <ProgressBar variant="info" now={relativeProgress.new} label={`${progress.new} new`} />
            <ProgressBar variant="success" now={relativeProgress.response} label={`${progress.response} responses`} />
        </ProgressBar>
    );
};

export default RecipientProgressBar;
