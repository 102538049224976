import React, { useRef, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAssignedMessages, getMessage, getRecipient, listSignatures } from '../services/api';
import '../styles/TestMessage.css';
import { Button } from 'react-bootstrap';

const TEMPLATE_SIZES = {
    "A4 Letter": { width: 794, height: 1123 },
    "A5 - Portrait": { width: 559, height: 794 },
    "A5 - Landscape": { width: 794, height: 559 },
    "A6 - Portrait": { width: 397, height: 559 },
    "A6 - Landscape": { width: 559, height: 397 },
    "DL Complement": { width: 312, height: 624 }
};

const MessagePreview = () => {
    const { campaignId, recipientId } = useParams();
    const navigate = useNavigate();
    const [messageContent, setMessageContent] = useState('');
    const [messageContentRight, setMessageContentRight] = useState('');
    const [fontStyle, setFontStyle] = useState('PremiumUltra23');
    const [selectedTemplate, setSelectedTemplate] = useState("A4 Letter");
    const [recipient, setRecipient] = useState({});
    const [missingVariables, setMissingVariables] = useState([]);
    const [signatures, setSignatures] = useState([]);
    const leftTextRef = useRef(null);
    const rightTextRef = useRef(null);

    useEffect(() => {
        const loadPreviewData = async () => {
            try {
                const recipientResponse = await getRecipient(recipientId);
                const recipientData = recipientResponse.data;
                setRecipient(recipientData);

                const assignedMessageResponse = await getAssignedMessages(recipientData.campaignId);
                const messageId = assignedMessageResponse.data.find(
                    msg => msg.campaignId === recipientData.campaignId
                )?.messageId;

                if (messageId) {
                    const messageResponse = await getMessage(messageId);
                    const { content: personalizedContent, missing } = fillMessageVariables(
                        messageResponse.data.messageContent, recipientData
                    );

                    setMessageContent(personalizedContent);
                    setMissingVariables(missing);

                    if (messageResponse.data.messageContentRight) {
                        const { content: rightContent } = fillMessageVariables(
                            messageResponse.data.messageContentRight, recipientData
                        );
                        setMessageContentRight(rightContent);
                    }

                    const style = messageResponse.data.robotStyle || 'Style 1';
                    handleRobotStyleChange(style);
                }
            } catch (error) {
                console.error('Error loading preview data:', error);
            }
        };

        const fetchSignatures = async () => {
            try {
                const signatureResponse = await listSignatures();
                setSignatures(signatureResponse.data || []);
            } catch (error) {
                console.error('Error fetching signatures');
            }
        };

        fetchSignatures();
        loadPreviewData();
    }, [campaignId, recipientId]);

    const handleRobotStyleChange = (style) => {
        const styles = {
            'Style 1': 'PremiumUltra23',
            'Style 2': 'PremiumUltra27',
            'Style 3': 'PremiumUltra52',
            'Style 4': 'PremiumUltra66',
            'Style 5': 'PremiumUltra11'
        };
        setFontStyle(styles[style] || 'PremiumUltra23');
    };

    const fillMessageVariables = (content = '', recipient) => {
        const missing = [];
        const variableLookup = {
            ...recipient,
            ...(recipient.customVariables || []).reduce((acc, cv) => ({ ...acc, [cv.name]: cv.value }), {}),
        };
    
        const personalizedContent = content.replace(/{{\s*(\w+)\s*}}/g, (_, variable) => {
            const value = variableLookup[variable];
            if (value === undefined) {
                missing.push(variable);
                return `{{${variable}}}`;
            }
            return value;
        });
    
        const contentWithSignatures = personalizedContent.replace(/{signature:\s*(.*?)\s*}/g, (match, signatureName) => {
            const matchedSignature = signatures.find(
                (sig) => sig.signatureName.toLowerCase() === signatureName.toLowerCase().trim()
            );
            if (matchedSignature) {
                const signatureHtml = matchedSignature.rawSig;
                return `<img src="data:image/svg+xml;base64,${btoa(signatureHtml)}" alt="${signatureName} signature" style="max-height: 60px; width: auto; object-fit: contain; display: block;" />`;
            } else {
                missing.push(`signature: ${signatureName}`);
                return `<div>{signature: ${signatureName}}</div>`;
            }
        });
    
        console.log('Processed Content with Signatures:', contentWithSignatures); // Debug to check if replacement worked
        return { content: contentWithSignatures, missing };
    };
    

    return (
        <div style={{ margin: 'auto', padding: '20px', border: '1px solid #ccc', maxWidth: '100%' }}>
            <Button
                variant="secondary"
                onClick={() => navigate(`/dashboard/recipients/${recipient.campaignId}`)}
                style={{ marginBottom: '20px' }}
            >
                Back to Recipients List
            </Button>

            <h2>Preview Message for {recipient.fullName || `${recipient.firstName || ''} ${recipient.lastName || ''}`}</h2>

            {missingVariables.length > 0 && (
                <div className="alert alert-warning">
                    Missing data for: {missingVariables.join(', ')}
                </div>
            )}

            {signatures.length > 0 && (
                <div className="alert alert-info">
                    Signatures may have additional spacing. Signature positioning is adjusted when written.
                </div>
            )}

            <div
                className="template-preview"
                style={{
                    width: TEMPLATE_SIZES[selectedTemplate].width,
                    minHeight: TEMPLATE_SIZES[selectedTemplate].height,
                    border: '1px solid #000',
                    backgroundColor: '#fff',
                    marginTop: '20px',
                    paddingBottom: '20px',
                    height: 'auto'
                }}
            >
                <div
                    className="editable-text"
                    ref={leftTextRef}
                    style={{
                        fontFamily: fontStyle,
                        whiteSpace: 'pre-wrap',
                        color: '#000080',
                        padding: '40px'
                    }}
                    dangerouslySetInnerHTML={{ __html: messageContent }}
                />

                {messageContentRight && (
                    <div
                        className="editable-text right-text"
                        ref={rightTextRef}
                        style={{
                            fontFamily: fontStyle,
                            whiteSpace: 'pre-wrap',
                            color: '#000080',
                            padding: '40px',
                            textAlign: 'right',
                            position: 'absolute',
                            top: '40px',
                            right: '40px',
                            width: '300px'
                        }}
                        dangerouslySetInnerHTML={{ __html: messageContentRight }}
                    />
                )}
            </div>
        </div>
    );
};

export default MessagePreview;
