import parse from 'html-react-parser';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Table, Tooltip,OverlayTrigger } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useUserContext } from '../services/UserProvider';
import { assignDesign, assignMessage, deleteCampaign, fetchCampaigns, getAllRecipientCounts, getAssignedDesigns, getAssignedMessages, getUserDesigns, getUserMessages, postingCountry, updateCampaign } from '../services/api';
import { log } from '../services/logger';
import CreateCampaignModal from './CreateCampaignModal';
import CampaignStatusCell from './CampaignStatusCell';
import CampaignNextStep from './CampaignNextStep';
import CampaignWizard from './CampaignWizard';

const useFetchData = (user, setUserId, setCampaigns, setAssignedMessages, setUserDesigns, setAssignedDesigns, setUserMessages, setRecipientCount, refreshData) => {
  useEffect(() => {
    if (user) {
      const decodedToken = jwtDecode(user);
      log(decodedToken);
      setUserId(decodedToken.userId);

      const fetchAllData = async () => {
        try {
          const [recipientCount, campaignsResponse, assignedMessagesResponse, userDesignsResponse, assignedDesignsResponse, userMessagesResponse] = await Promise.all([
            getAllRecipientCounts(),
            fetchCampaigns(decodedToken.userId),
            getAssignedMessages(decodedToken.userId),
            getUserDesigns(decodedToken.userId),
            getAssignedDesigns(decodedToken.userId),
            getUserMessages(decodedToken.userId),
          ]);
          setCampaigns(campaignsResponse.data);
          setAssignedMessages(assignedMessagesResponse.data);
          setUserDesigns(userDesignsResponse.data);
          setAssignedDesigns(assignedDesignsResponse.data);
          setUserMessages(userMessagesResponse.data);
          setRecipientCount(recipientCount.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchAllData();
    }
  }, [user, setUserId, setCampaigns, setAssignedMessages, setUserDesigns, setAssignedDesigns, setUserMessages, setRecipientCount, refreshData]);
};

const CampaignsPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [showWizard, setShowWizard] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shouldOpenModal = queryParams.get('new') === 'true';
  const handleShowModal = () => setShowModal(true);
  const { user } = useUserContext(); // Use the user context
  const [campaigns, setCampaigns] = useState([]); // State to store the list of campaigns
  const [userId, setUserId] = useState(null);
  const [editingCampaign, setEditingCampaign] = useState(null); // Track the campaign being edited
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showDesignAssignModal, setShowAssignDesignModal] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [assignedMessages, setAssignedMessages] = useState([]);
  const [assignedDesigns, setAssignedDesigns] = useState([]);
  const [userMessages, setUserMessages] = useState([]);
  const [userDesigns, setUserDesigns] = useState([]);
  const [recipientCount, setRecipientCount] = useState([]);
  const [userCountry, setUserCountry] = useState([]);
  const [refreshData, setRefreshData] = useState(false); // A state to trigger refresh
  const [expandedParents, setExpandedParents] = useState([]); // Track which parent campaigns are expanded

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingCampaign(null); // Reset editing message
  };

    // Helper function to find custom fields by name
    const getCustomFieldValue = (customFields, fieldName) => {
      const field = customFields.find(f => f.name === fieldName);
      return field ? field.value : 'N/A';
    };

  
    const renderTooltip = (props, campaign) => (
      <Tooltip id="button-tooltip" {...props}>
        <strong>Created At:</strong> {campaign.createdAt}<br />
        {/* Loop through custom fields, excluding 'createdAt' */}
        {campaign.customFields
          .filter(field => field.label !== 'createdAt')
          .map((field) => (
            <div key={field._id}>
              <strong>{field.label}:</strong> 
              {/* Explicitly handle boolean values */}
              {typeof field.value === 'boolean' ? (field.value ? 'Yes' : 'No') : field.value}<br />
            </div>
          ))}
      </Tooltip>
    );
    

  // Re-fetch campaigns whenever refreshData changes
  useFetchData(user, setUserId, setCampaigns, setAssignedMessages, setUserDesigns, setAssignedDesigns, setUserMessages, setRecipientCount, refreshData);

  useEffect(() => {
    const fetchAndSetUserCountry = async () => {
      try {
        const countryData = await postingCountry(); // Adjust as necessary for your API call
        setUserCountry(countryData.data.postingCountry); // Set the fetched data
        log(countryData.data.postingCountry);
      } catch (error) {
        console.error('Error fetching user country:', error);
      }
    };

    fetchAndSetUserCountry();
  }, []);

  useEffect(() => {
    if (shouldOpenModal) {
      setShowModal(true);
    }
  }, [shouldOpenModal]);

  // Call this function after actions like updating, deleting, etc.
  const triggerCampaignReload = () => {
    setRefreshData(!refreshData); // This will trigger useEffect to reload campaigns
  };

  // Group campaigns by parent-child relationship
  const groupCampaignsByParent = (campaigns) => {
    const parentChildMap = {};

    campaigns.forEach((campaign) => {
      if (campaign.parentCampaign) {
        if (!parentChildMap[campaign.parentCampaign]) {
          parentChildMap[campaign.parentCampaign] = { parent: null, children: [] };
        }
        parentChildMap[campaign.parentCampaign].children.push(campaign);
      } else {
        if (!parentChildMap[campaign._id]) {
          parentChildMap[campaign._id] = { parent: null, children: [] };
        }
        parentChildMap[campaign._id].parent = campaign;
      }
    });

    return Object.values(parentChildMap);
  };

  const groupedCampaigns = groupCampaignsByParent(campaigns);

  const toggleExpandParent = (parentId) => {
    if (expandedParents.includes(parentId)) {
      setExpandedParents(expandedParents.filter(id => id !== parentId)); // Collapse if expanded
    } else {
      setExpandedParents([...expandedParents, parentId]); // Expand if collapsed
    }
  };

  const handleEditCampaign = (campaign) => {
    setEditingCampaign(campaign); // Set the campaign to be edited
    handleShowModal(); // Open the modal
  };

  const handleEditCampaignWithWizard = (campaign) => {
    setEditingCampaign(campaign); // Set the campaign to be edited
    setShowWizard(true); // Open the CampaignWizard
  };

  const handleSubmitForReview = (campaign) => {
    if (!assignedMessages.find(m => m.campaignId === campaign._id)) {
      alert("please assign a message before submitting for review");
    } else if (!assignedDesigns.find(d => d.campaignId === campaign._id)) {
      alert("please assign a design before submitting for review");
    } else if (campaign.statusV1 === 'pending' || campaign.statusV1 === 'draft') {
      const inReviewCampaign = { ...campaign, 'statusV1': 'in-review' }
      updateCampaign(campaign._id, inReviewCampaign).then(() => {
        triggerCampaignReload(); // Reload campaigns after submission
      });
    }
  }

  const handleCancelReview = (campaign) => {
    if (campaign.statusV1 === 'in-review') {
      const inReviewCampaign = { ...campaign, 'statusV1': 'draft' }
      updateCampaign(campaign._id, inReviewCampaign).then(() => {
        triggerCampaignReload(); // Reload campaigns after cancellation
      });
    }
  }

  const handleCloseCampaign = (campaign) => {
    if (campaign.statusV1 === 'live') {
      if (window.confirm("Are you sure you want to close this campaign?  Once closed it will no longer be able to accept new recipients for posting")) {
        const closedCampaign = { ...campaign, 'statusV1': 'closed' }
        updateCampaign(campaign._id, closedCampaign).then(() => {
          triggerCampaignReload(); // Reload campaigns after closing
        });
      }
    }
  }

  const handleDeleteCampaign = (campaignId) => {
    if (window.confirm("Are you sure you want to delete this campaign? Your recipients associated to your campaign will be deleted as well. It's not reversable.?")) {
      deleteCampaign(campaignId)
        .then(() => {
          log('Campaign deleted');
          triggerCampaignReload(); // Reload campaigns after deletion
        })
        .catch(error => {
          console.error('Error deleting campaign:', error);
        });
    }
  };

  const isEditableCampaign = (campaign) => {
    return campaign.statusV1 === 'pending' || campaign.statusV1 === 'draft';
  }

  const createMessageCell = (campaign, messageId) => {
    const message = userMessages.find(msg => msg._id === messageId);
    return message
      ? <>
        {message.messageName} {isEditableCampaign(campaign) && <Button variant="outline-primary" size="sm" onClick={() => handleOpenAssignMessage(campaign, messageId)}><i className="bi bi-pencil" /></Button>}
      </>
      : <Button variant="warning" size="sm" onClick={() => handleOpenAssignMessage(campaign)}>Assign Message</Button>;
  };

  const createDesignCell = (campaign, designId) => {
    const design = userDesigns.find(des => des._id === designId);
    return design
      ? <>
        {design.designName} {isEditableCampaign(campaign) && <Button variant="outline-primary" size="sm" onClick={() => handleOpenAssignDesign(campaign, designId)}><i className="bi bi-pencil" /></Button>}
      </>
      : <Button variant="warning" size="sm" onClick={() => handleOpenAssignDesign(campaign)}>Assign Design</Button>
  }

  const createRecipientCell = (campaignId) => {
    const campaignData = recipientCount.find(rc => rc.campaignId === campaignId);
    return <>
      {campaignData ? campaignData.recipientCount + " Recipients" : 'No Recipients Assigned'}
      <Button variant="outline-primary" className="mx-2" size="sm" as={Link} to={"/dashboard/recipients/" + campaignId}><i className="bi bi-pencil" /></Button>
    </>;
  };

  const handleOpenAssignMessage = (campaign, messageId) => {
    setSelectedMessage(messageId);
    setSelectedCampaignId(campaign._id); // Store the campaign ID
    setShowAssignModal(true);
  };

  const handleOpenAssignDesign = (campaign, designId) => {
    setSelectedDesign(designId)
    setSelectedCampaignId(campaign._id); // Store the campaign ID
    setShowAssignDesignModal(true);
  };

  const handleCloseAssignModal = () => {
    setShowAssignModal(false); // Hide the modal
    setSelectedMessage(null); // Reset the selected message
    setShowAssignDesignModal(false);
    setSelectedDesign(null);
  };

  const handleAssignMessage = async () => {
    try {
      const assignmentData = {
        userId: userId,
        campaignId: selectedCampaignId,
        messageId: selectedMessage
      };

      await assignMessage(assignmentData);
      log('Message assigned successfully');
    } catch (error) {
      console.error('Error assigning message:', error);
    }
    setShowAssignModal(false);
    triggerCampaignReload(); // Reload campaigns after assignment
  };

  const handleAssignDesign = async () => {
    try {
      const assignmentData = {
        userId: userId,
        campaignId: selectedCampaignId,
        designId: selectedDesign
      };

      await assignDesign(assignmentData);
      log('Design assigned successfully');
      setShowAssignDesignModal(false);
    } catch (error) {
      console.error('Error assigning design:', error);
    }
    triggerCampaignReload(); // Reload campaigns after assignment
  };

  const handleCreateNewCampaign = () => {
    setEditingCampaign(null); // Clear the editingCampaign state
    setShowWizard(true); // Open the CampaignWizard
  };

  return (
    <Container fluid>
      <Row>
        <Col><h1>Campaigns</h1></Col>
        <Col><div className='float-end'></div></Col>
        <Col><Button onClick={() => handleCreateNewCampaign(true)}>Create New Campaign</Button>
          <CampaignWizard
            show={showWizard}
            onHide={() => setShowWizard(false)}
            userId={userId}
            editingCampaign={editingCampaign}
          /></Col>
      </Row>
      <Row>
        <p className='text-muted'> Campaigns bring messages, designs, and recipients together to carry your handwritten messages around the world.</p>
      </Row>
      <Row className='mt-3'>
        {/* Table to display campaigns */}
        {/* Table to display campaigns */}
      {/* Table to display campaigns */}
      {(campaigns.length === 0)
        ? <><div className='d-flex justify-content-center'>Looks like you don't have any campaigns - let's make our first one!</div></>
        :
        <Table striped bordered hover className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Campaign Name</th>
              <th>Status</th>
              <th>Message</th>
              <th>Designs</th>
              <th>Recipients</th>
              <th>Actions</th>
              <th>Next Step</th>
            </tr>
          </thead>
          <tbody>
            {groupedCampaigns.map(({ parent, children }) => (
              <React.Fragment key={parent ? parent._id : Math.random()}>
                {parent && (
                  <tr>
                    <td>
                      {/* Tooltip for Campaign Name with custom fields */}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => renderTooltip(props, parent)}
                      >
                        <span style={{ cursor: 'pointer' }}>
                          {children.length > 0 && (
                            <span onClick={() => toggleExpandParent(parent._id)}>
                              {expandedParents.includes(parent._id) ? '▼' : '►'}
                            </span>
                          )}
                          {' '}
                          <strong>{parent.campaignName}</strong>
                        </span>
                      </OverlayTrigger>
                    </td>
                    <td><CampaignStatusCell campaign={parent} /></td>
                    <td>{createMessageCell(parent, assignedMessages.find(msg => msg.campaignId === parent._id)?.messageId)}</td>
                    <td>{createDesignCell(parent, assignedDesigns.find(design => design.campaignId === parent._id)?.designId)}</td>
                    <td>{createRecipientCell(parent._id)}</td>
                    <td>
                      <Button variant="outline-danger" size="sm" onClick={() => handleDeleteCampaign(parent._id)}>
                        <i className="bi bi-trash"></i>
                      </Button>
                    </td>
                    <td>
                      <CampaignNextStep
                        campaign={parent}
                        handleSubmitForReview={handleSubmitForReview}
                        assignedDesign={assignedDesigns.find(design => design.campaignId === parent._id)}
                        assignedMessage={assignedMessages.find(msg => msg.campaignId === parent._id)}
                        campaignData={recipientCount.find(rc => rc.campaignId === parent._id)}
                        handleCancelReview={handleCancelReview}
                        handleCloseCampaign={handleCloseCampaign}
                      />
                    </td>
                  </tr>
                )}
                {/* Child Campaign Rows */}
                {expandedParents.includes(parent._id) && children.map(child => (
                  <tr key={child._id} style={{ backgroundColor: '#f0f0f0' }}>
                    <td style={{ paddingLeft: '30px' }}>
                      {/* Tooltip for Child Campaign */}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => renderTooltip(props, child)}
                      >
                        <span style={{ cursor: 'pointer' }}>{`→ ${child.campaignName}`}</span>
                      </OverlayTrigger>
                    </td>
                    <td><CampaignStatusCell campaign={child} /></td>
                    <td>{createMessageCell(child, assignedMessages.find(msg => msg.campaignId === child._id)?.messageId)}</td>
                    <td>{createDesignCell(child, assignedDesigns.find(design => design.campaignId === child._id)?.designId)}</td>
                    <td>{createRecipientCell(child._id)}</td>
                    <td>
                      <Button variant="outline-danger" size="sm" onClick={() => handleDeleteCampaign(child._id)}>
                        <i className="bi bi-trash"></i>
                      </Button>
                    </td>
                    <td>
                      <CampaignNextStep
                        campaign={child}
                        handleSubmitForReview={handleSubmitForReview}
                        assignedDesign={assignedDesigns.find(design => design.campaignId === child._id)}
                        assignedMessage={assignedMessages.find(msg => msg.campaignId === child._id)}
                        campaignData={recipientCount.find(rc => rc.campaignId === child._id)}
                        handleCancelReview={handleCancelReview}
                        handleCloseCampaign={handleCloseCampaign}
                      />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      }
        <CreateCampaignModal showModal={showModal} setShowModal={setShowModal} handleCloseModal={handleCloseModal} userCountry={userCountry} editingCampaign={editingCampaign} userId={userId} />

        <Modal show={showAssignModal} onHide={() => setShowAssignModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Message</Modal.Title>
          </Modal.Header>
          {
            (userMessages.length === 0)
              ?
              <>
                <Modal.Body>
                  <div className="d-flex justify-content-center">
                    Looks like you haven't created a message yet.
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Link to="/dashboard/messages/create"><Button>Create New Message</Button></Link>
                  </div>
                </Modal.Body>
              </>
              :
              <>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="messageDropdown">
                      <Form.Label>Select a Message</Form.Label>
                      <Form.Control as="select" onChange={(e) => setSelectedMessage(e.target.value)} defaultValue={selectedMessage ? selectedMessage : ''}>
                        <option disabled value="">Please select your message to view</option>
                        {userMessages.map((msg) => (
                          <option key={msg._id} value={msg._id}>{msg.messageName}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="messageContent">
                      <Form.Label>Message Content</Form.Label>
                      <div className="read-only-textarea">
                        {selectedMessage ? parse(userMessages.find(msg => msg._id === selectedMessage).messageContent) : ''}
                      </div>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAssignModal}>Close</Button>
                  <Button variant="primary" disabled={!selectedMessage} onClick={handleAssignMessage}>Assign Message</Button>
                </Modal.Footer>
              </>
          }

        </Modal>

        <Modal show={showDesignAssignModal} onHide={() => setShowAssignDesignModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Design</Modal.Title>
          </Modal.Header>
          {
            (userDesigns.length === 0)
              ?
              <>
                <Modal.Body>
                  <div className="d-flex justify-content-center">
                    Looks like you haven't created a design yet.
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Link to="/dashboard/designs/"><Button>Create New Design</Button></Link>
                  </div>
                </Modal.Body>
              </>
              :
              <>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="designDropdown">
                      <Form.Label>Select a Design</Form.Label>
                      <Form.Control as="select" onChange={(e) => setSelectedDesign(e.target.value)} defaultValue={selectedDesign ? selectedDesign : ''}>
                        <option disabled value="">Please select your design to view</option>
                        {userDesigns && userDesigns.length > 0 && userDesigns.map((dsg) => (
                          <option key={dsg._id} value={dsg._id}>{dsg.designName}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAssignModal}>Close</Button>
                  <Button variant="primary" disabled={!selectedDesign} onClick={handleAssignDesign}>Assign Design</Button>
                </Modal.Footer>
              </>
          }
        </Modal>
      </Row>
    </Container>
  );
};

export default CampaignsPage;
