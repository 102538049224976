import React, { useState, useEffect } from 'react'; // Import useState hook
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, Button, Container, Row, Col, Card, Alert } from 'react-bootstrap'; // Import Alert for displaying the error message
import authService from '../services/authService';
import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from '../services/UserProvider';
import axios from 'axios';
import logo from '../penneduk-logo.svg';


const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required').min(6, 'Minimum six characters'),
  location: Yup.string().required('Required'), // Add location validation
});

const SignUpPage = () => {
  let navigate = useNavigate();
  const { setUser, login, updateUserToken } = useUserContext();
  const [signupError, setSignupError] = useState(''); // State to hold the signup error message
  const [userLocation, setUserLocation] = useState('AU'); // default to australia
  
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Img variant='top' style={{height: 100}} src={logo} />
              <Card.Title className="text-center mb-4">Sign Up</Card.Title>
              <Formik
                initialValues={{ email: '', password: '', location: userLocation  }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                  try {
                    // Signup the user
                    const signupResponse = await authService.signup(
                      values.email,
                      values.password,
                      values.location,
                      '' // dead ip field
                    );
                    
                    // Check if the user is active or pending approval
                    const { isActive } = signupResponse.data;
                    
                    if (isActive) {
                      // If the user is active, log them in and redirect to dashboard
                      const signinResponse = await authService.signin(values.email, values.password);
                      const { token } = signinResponse.data;
                      
                      // Store the token in localStorage
                      localStorage.setItem('jwtToken', token);
                      
                      // Set the user context and navigate to the dashboard
                      setUser(signinResponse.data.userId);
                      navigate('/dashboard');
                    } else {
                      // If the account is pending approval, redirect to pending approval page
                      navigate('/pending-approval');
                    }
                  } catch (error) {
                    console.error('Error during the signup process:', error);
                  
                    // Check if the error is a 403 response indicating pending approval
                    if (error.response && error.response.status === 403) {
                      console.log('403 error response: User pending approval');
                      navigate('/pending-approval');  // Redirect to pending approval
                    } else {
                      // Handle other errors (e.g., server errors, invalid input)
                      setSignupError(`The following error occurred during signup: ${error.response?.data || error.message}`);
                    }
                  }
                  
                }}
                
                // onSubmit={async (values) => {
                //   try {
                //     //const signupResponse = await authService.signup(values.email, values.password, values.location);
                //     // Signup the user
                //     const signupResponse = await authService.signup(
                //       values.email,
                //       values.password,
                //       values.location,
                //       '' // dead ip field
                //     );

                //     //console.log('User signed up:', signupResponse.data);
                    
                //     // Assuming the `login` function here does the same as `authService.signin` but also handles setting the user context/state
                //     const signinResponse = await authService.signin(values.email, values.password);
                //     //console.log('User signed in:', signinResponse.data);
                    
                //     // Assuming signinResponse.data contains a token
                //     const { token } = signinResponse.data;
                    
                //     // Store the token in localStorage
                //     localStorage.setItem('jwtToken', token);
                    
                //     // Update any context or state as necessary
                //     // Assuming `setUser` is a function to update user context/state
                //     setUser(signinResponse.data.userId); // Adjust as needed based on your application's structure
                    
                //     // Redirect the user to the dashboard
                //     navigate('/dashboard');
                //   } catch (error) {
                //     console.error('Error during the signup/signin process:', error);
                //     // Here, you might need to differentiate between signup and signin errors
                //     // For demonstration, I'm setting a generic error, but you should adjust as necessary
                //     setSignupError(`The following error occurred during signup/signin: ${error.response.data}`);
                //   }
                // }}
              >
                {({ errors, touched, handleSubmit, handleChange, values }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    {signupError && <Alert variant="danger">{signupError}</Alert>} {/* Conditionally render the error message */}
                    
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && !!errors.email}
                        placeholder="Enter email"
                      />
                      <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isValid={touched.password && !errors.password}
                        isInvalid={touched.password && !!errors.password}
                        placeholder="Password"
                      />
                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicLocation">
                      <Form.Label>Location</Form.Label>
                      <Form.Control
                        as="select"
                        name="location"
                        value={values.location}
                        onChange={handleChange}
                        isValid={touched.location && !errors.location}
                        isInvalid={touched.location && !!errors.location}
                      >
                        <option value="AU">Australia</option>
                        <option value="GB">United Kingdom</option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit" className="w-100">
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
              <Col className="my-3">
                <p>Already have an account? <Link to="/signin">Sign in here</Link>.</p>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpPage;
