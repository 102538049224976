// import React, { useRef, useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { getAssignedMessages, getMessage, getRecipient, listSignatures } from '../services/api';
// import '../styles/TestMessage.css';
// import { fillMessageVariables } from '../utils/messageUtils';
// import { Button } from 'react-bootstrap';

// const TEMPLATE_SIZES = {
//     "A4 Letter": { width: 794, height: 1123 },
//     "A5 - Portrait": { width: 559, height: 794 },
//     "A5 - Landscape": { width: 794, height: 559 },
//     "A6 - Portrait": { width: 397, height: 559 },
//     "A6 - Landscape": { width: 559, height: 397 },
//     "DL Complement": { width: 312, height: 624 }
// };

// const TestMessage = () => {
//     const { campaignId, recipientId } = useParams();
//     const navigate = useNavigate(); // Use navigate hook

//     const [messageContent, setMessageContent] = useState('');
//     const [messageContentRight, setMessageContentRight] = useState(''); // New state for right content
//     const [fontStyle, setFontStyle] = useState('PremiumUltra23');
//     const [selectedTemplate, setSelectedTemplate] = useState("A4 Letter");
//     const [isOverflow, setIsOverflow] = useState(false);
//     const [recipient, setRecipient] = useState([]);
//     const leftTextRef = useRef(null);
//     const rightTextRef = useRef(null);

//     useEffect(() => {
//         console.log(`Loading data for campaignId: ${campaignId}, recipientId: ${recipientId}`);
//         const loadPreviewData = async () => {
//             try {
//                 const recipientResponse = await getRecipient(recipientId);
//                 const recipientData = recipientResponse.data;
//                 setRecipient(recipientData);
    
//                 const assignedMessageResponse = await getAssignedMessages(recipientData.campaignId);
//                 const messageId = assignedMessageResponse.data.find(
//                     msg => msg.campaignId === recipientData.campaignId
//                 )?.messageId;
    
//                 if (messageId) {
//                     const messageResponse = await getMessage(messageId);
//                     const { content: personalizedContent, rightContent: personalizedContentRight } = fillMessageVariables(
//                         messageResponse.data.messageContent, recipientData
//                     );

//                     setMessageContent(personalizedContent);

//                     // Check if messageContentRight exists and set it
//                     if (messageResponse.data.messageContentRight) {
//                         const { content: rightContent } = fillMessageVariables(
//                             messageResponse.data.messageContentRight, recipientData
//                         );
//                         setMessageContentRight(rightContent);
//                     }
    
//                     // Set font style based on message data
//                     const style = messageResponse.data.robotStyle || 'Style 1';
//                     handleRobotStyleChange(style);
//                 } else {
//                     console.warn('No message ID found for the given campaign.');
//                 }
//             } catch (error) {
//                 console.error('Error loading preview data:', error);
//             }
//         };
        
//         loadPreviewData();
//     }, [campaignId, recipientId]);
    

//     const handleRobotStyleChange = (style) => {
//         const styles = {
//             'Style 1': 'PremiumUltra23',
//             'Style 2': 'PremiumUltra27',
//             'Style 3': 'PremiumUltra52',
//             'Style 4': 'PremiumUltra66',
//             'Style 5': 'PremiumUltra11'
//         };
//         setFontStyle(styles[style] || 'PremiumUltra23');
//     };

//     return (
//         <div style={{ margin: 'auto', padding: '20px', border: '1px solid #ccc', maxWidth: '100%' }}>
//                 <Button
//                 variant="secondary"
//                 onClick={() => navigate(`/dashboard/recipients/${recipient.campaignId}`)}
//                 style={{ marginBottom: '20px' }}
//             >
//                 Back to Recipients List
//             </Button>
//            <h2>Preview Message for {recipient.fullName || `${recipient.firstName || ''} ${recipient.lastName || ''}`}</h2>
//             <h4>This is a dynamic preview of the message being written. The variable and message detail is what will be written. However, your message may be displayed on multiple pages depending on the width.</h4>
//             <div 
//                 className="template-preview" 
//                 style={{
//                     width: TEMPLATE_SIZES[selectedTemplate].width, 
//                     minHeight: TEMPLATE_SIZES[selectedTemplate].height, // Use minHeight instead of height
//                     position: 'relative',
//                     border: '1px solid #000',
//                     backgroundColor: '#fff',
//                     marginTop: '20px',
//                     paddingBottom: '20px', // Optional: add padding for readability
//                     height: 'auto' // Let container grow based on content
//                 }}
//             >
//                 <div 
//                     className="editable-text" 
//                     ref={leftTextRef}
//                     style={{
//                         fontFamily: fontStyle,
//                         whiteSpace: 'pre-wrap',
//                         color: '#000080',
//                         padding: '40px'
//                     }}
//                     dangerouslySetInnerHTML={{ __html: messageContent }}
//                 />

//                 {/* Conditionally render right-aligned section if messageContentRight exists */}
//                 {messageContentRight && (
//                     <div 
//                         className="editable-text right-text" 
//                         ref={rightTextRef}
//                         style={{
//                             fontFamily: fontStyle,
//                             whiteSpace: 'pre-wrap',
//                             color: '#000080',
//                             padding: '40px',
//                             textAlign: 'right',
//                             position: 'absolute',
//                             top: '40px',
//                             right: '40px',
//                             width: '300px' // Adjust width as necessary
//                         }}
//                         dangerouslySetInnerHTML={{ __html: messageContentRight }}
//                     />
//                 )}
//             </div>
//         </div>
//     );
// };

// export default TestMessage;


import React, { useRef, useState, useEffect } from 'react';
import '../styles/TestMessage.css';

const TEMPLATE_SIZES = {
    "A4 Letter": { width: 794, height: 1123 },
    "A5 - Portrait": { width: 559, height: 794 },
    "A5 - Landscape": { width: 794, height: 559 },
    "A6 - Portrait": { width: 397, height: 559 },
    "A6 - Landscape": { width: 559, height: 397 },
    "DL Complement": { width: 312, height: 624 }
};

const App = () => {
    const leftTextRef = useRef(null);
    const rightTextRef = useRef(null);

    const [selectedTemplate, setSelectedTemplate] = useState("A4 Letter");
    const [fontStyle, setFontStyle] = useState('PremiumUltra23');
    const [lineHeightMultiplier] = useState(1.2);
    const [isOverflow, setIsOverflow] = useState(false);

    const { width, height } = TEMPLATE_SIZES[selectedTemplate];

    const checkOverflow = (ref, templateHeight) => {
        if (ref.current) {
            const contentHeight = ref.current.scrollHeight;
            const lineHeight = 25.3333 * lineHeightMultiplier;
            return contentHeight + lineHeight >= templateHeight;
        }
        return false;
    };

    const handleRobotStyleChange = (style) => {
        let selectedFontStyle;
        switch (style) {
            case 'Style 1': selectedFontStyle = 'PremiumUltra23'; break;
            case 'Style 2': selectedFontStyle = 'PremiumUltra27'; break;
            case 'Style 3': selectedFontStyle = 'PremiumUltra52'; break;
            case 'Style 4': selectedFontStyle = 'PremiumUltra66'; break;
            case 'Style 5': selectedFontStyle = 'PremiumUltra11'; break;
            default: selectedFontStyle = 'PremiumUltra23'; break;
        }
        setFontStyle(selectedFontStyle);
    };

    const applyStyles = (ref, fontFamily) => {
        if (!ref.current) return; // Ensure ref is not null

        const fontSize = 25.3333;
        const lineHeight = fontSize * lineHeightMultiplier;
        const children = Array.from(ref.current.childNodes);
        children.forEach((child) => {
            if (child.nodeType === Node.TEXT_NODE) {
                const span = document.createElement('span');
                span.style.fontSize = `${fontSize}px`;
                span.style.lineHeight = `${lineHeight}px`;
                span.style.fontFamily = `${fontFamily}, Arial, sans-serif`;
                span.style.color = '#000080';
                span.style.whiteSpace = 'pre-wrap';
                span.textContent = child.textContent;
                ref.current.replaceChild(span, child);
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                child.style.fontSize = `${fontSize}px`;
                child.style.lineHeight = `${lineHeight}px`;
                child.style.fontFamily = `${fontFamily}, Arial, sans-serif`;
                child.style.color = '#000080';
                child.style.whiteSpace = 'pre-wrap';
                if (ref === rightTextRef) child.style.textAlign = 'right'; // Ensure right alignment
            }
        });
    };

    const handleInputChange = () => {
        applyStyles(leftTextRef, fontStyle);
        if (selectedTemplate === "A4 Letter") {
            applyStyles(rightTextRef, fontStyle);
        }
        const leftOverflow = checkOverflow(leftTextRef, height);
        const rightOverflow = selectedTemplate === "A4 Letter" && checkOverflow(rightTextRef, height);
        setIsOverflow(leftOverflow || rightOverflow);
    };

    useEffect(() => {
        handleInputChange();
    }, [fontStyle, selectedTemplate]);

    const handleTemplateChange = (event) => {
        setSelectedTemplate(event.target.value);
    };

    const handlePaste = (e, ref) => {
        e.preventDefault(); // Prevent default paste to keep styles

        const text = e.clipboardData.getData('text/plain'); // Get plain text
        document.execCommand('insertText', false, text); // Insert as plain text to keep formatting

        // Reapply styles to ensure alignment and other properties are preserved
        applyStyles(ref, fontStyle);
    };

    return (
        <div style={{ margin: 'auto', padding: '20px', border: '1px solid #ccc', maxWidth: '100%' }}>
            <h2>A4 Editor</h2>
            {isOverflow && (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#ffcccc',
                    color: '#a94442',
                    padding: '10px 20px',
                    border: '1px solid #a94442',
                    borderRadius: '4px',
                    marginTop: '10px',
                    position: 'relative',
                    maxWidth: '90%',
                }}>
                    <span style={{ fontWeight: 'bold', marginRight: '8px' }}>Warning:</span>
                    <span> The text exceeds the template bounds!</span>
                    <button
                        onClick={() => setIsOverflow(false)}
                        style={{
                            background: 'none',
                            border: 'none',
                            color: '#a94442',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            position: 'absolute',
                            right: '10px',
                            top: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        &times;
                    </button>
                </div>
            )}
            <label>
                Choose Template:
                <select value={selectedTemplate} onChange={handleTemplateChange}>
                    <option value="A4 Letter">A4 Letter</option>
                    <option value="A5 - Portrait">A5 - Portrait</option>
                    <option value="A5 - Landscape">A5 - Landscape</option>
                    <option value="A6 - Portrait">A6 - Portrait</option>
                    <option value="A6 - Landscape">A6 - Landscape</option>
                    <option value="DL Complement">DL Complement</option>
                </select>
            </label>

            <label style={{ marginLeft: '20px' }}>
                Choose Handwriting Style:
                <select onChange={(e) => handleRobotStyleChange(e.target.value)}>
                    <option value="Style 1">Style 1</option>
                    <option value="Style 2">Style 2</option>
                    <option value="Style 3">Style 3</option>
                    <option value="Style 4">Style 4</option>
                    <option value="Style 5">Style 5</option>
                </select>
            </label>

            <div 
                className="template-preview" 
                style={{
                    width: `${width}px`, 
                    height: `${height}px`, 
                    position: 'relative',
                    border: '1px solid #000',
                    backgroundColor: '#fff',
                    marginTop: '20px'
                }}
            >
                <div
                    className="editable-text left-text"
                    contentEditable
                    suppressContentEditableWarning
                    ref={leftTextRef}
                    style={{
                        position: 'absolute',
                        top: '100px',
                        left: '50px',
                        width: '700px',
                        textAlign: 'left',
                        whiteSpace: 'pre-wrap',
                        fontFamily: `${fontStyle}, Arial, sans-serif`,
                    }}
                    onInput={handleInputChange}
                >
                    Left Aligned Text
                </div>

                {selectedTemplate === "A4 Letter" && (
                    <div
                        className="editable-text right-text"
                        contentEditable
                        suppressContentEditableWarning
                        ref={rightTextRef}
                        style={{
                            position: 'absolute',
                            top: '100px',
                            left: '400px',
                            width: '300px',
                            textAlign: 'right',
                            whiteSpace: 'pre-wrap',
                            fontFamily: `${fontStyle}, Arial, sans-serif`,
                        }}
                        onInput={handleInputChange}
                        onPaste={(e) => handlePaste(e, rightTextRef)}
                    >
                        Right Aligned Text
                    </div>
                )}
            </div>

            
        </div>
    );
};

export default App;
