export const defaultRecipientFields = [
    "title",
    "firstName",
    "lastName",
    "fullName",
    "companyName",
    "addressLine1",
    "addressLine2",
    "addressLine3",
    "cityCounty",
    "postcode",
    "country",
    "requestedPostDate"
  ];
  