import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createCampaign, updateCampaign, listProductTypes, listPapers, listEnvelopesType, listServices, fetchCustomFields } from '../services/api';
import { log } from '../services/logger';

const CreateCampaignModal = ({ showModal, setShowModal, handleCloseModal, userCountry, editingCampaign, userId }) => {
  const [productTypes, setProductTypes] = useState([]);
  const [papers, setPapers] = useState([]);
  const [envelopes, setEnvelopes] = useState([]);
  const [services, setServices] = useState([]);
  const [customFields, setCustomFields] = useState([]); // To store fetched custom fields
  const [customFieldValues, setCustomFieldValues] = useState({}); // To store values for custom fields

  const fetchData = useCallback(async () => {
    try {
      const [types, papers, envelopes, services] = await Promise.all([
        listProductTypes(),
        listPapers(),
        listEnvelopesType(),
        listServices(),
      ]);
      setProductTypes(types);
      setPapers(papers);
      setEnvelopes(envelopes);
      setServices(services);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (editingCampaign) {
      formik.setValues({
        campaignName: editingCampaign.campaignName,
        productType: editingCampaign.productType || '',
        paperType: editingCampaign.paperType || '',
        envelopeType: editingCampaign.envelopeType || '',
        serviceType: editingCampaign.serviceType || '',
      });

      // Fetch custom fields for this campaign (if editing)
      fetchCustomFields('Campaigns').then(response => {
        setCustomFields(response.data);
        setCustomFieldValues(
          editingCampaign.customFields.reduce((acc, field) => {
            acc[field.label] = field.value;
            return acc;
          }, {})
        );
      });
    } else {
      formik.resetForm();
    }
  }, [editingCampaign]);

  // Fetch custom fields based on campaign name input
  const handleCampaignNameChange = async (e) => {
    formik.handleChange(e);
    const campaignName = e.target.value;
  
    if (campaignName) {
      try {
        console.log('Fetching custom fields for campaign:', campaignName); // Debug log
        const response = await fetchCustomFields('Campaigns');
        setCustomFields(response.data);
        console.log('Custom fields fetched:', response.data); // Debug log
      } catch (error) {
        console.error('Error fetching custom fields:', error);
      }
    }
  };
  

  // Handle input changes for custom fields
  const handleCustomFieldChange = (label, value) => {
    setCustomFieldValues({
      ...customFieldValues,
      [label]: value,
    });
  };

  const formik = useFormik({
    initialValues: {
      campaignName: '',
      productType: '',
      paperType: '',
      envelopeType: '',
      serviceType: '',
    },
    validationSchema: Yup.object().shape({
      campaignName: Yup.string().required('Campaign Name is required'),
      productType: Yup.string().required('Product Type is required'),
      paperType: Yup.string().required('Paper Type is required'),
      envelopeType: Yup.string().required('Envelope Type is required'),
      serviceType: Yup.string().required('Service Type is required'),
    }),
    onSubmit: (values) => {
      const submissionValues = {
        ...values,
        userId: userId,
        customFields: Object.keys(customFieldValues).map(label => ({
          label,
          value: customFieldValues[label],
        })), // Include custom fields in the submission
      };

      if (editingCampaign) {
        updateCampaign(editingCampaign._id, submissionValues)
          .then((response) => {
            log('Campaign updated successfully');
            setShowModal(false);
          })
          .catch((error) => {
            console.error('Error updating campaign: ', error);
          });
      } else {
        createCampaign(submissionValues)
          .then((response) => {
            log('Campaign created successfully:', response.data);
            setShowModal(false);
          })
          .catch((error) => {
            console.error('Error creating campaign:', error);
          });
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit(e);
  };

  return (
    <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Campaign Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="campaignName">
            <Form.Label>Campaign Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter campaign name"
              name="campaignName"
              value={formik.values.campaignName}
              onChange={handleCampaignNameChange} // Custom function to load custom fields
            />
            {formik.touched.campaignName && formik.errors.campaignName && (
              <div className="error">{formik.errors.campaignName}</div>
            )}
          </Form.Group>

          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formProductType">
                <Form.Label>Product Type</Form.Label>
                <Form.Control
                  as="select"
                  name="productType"
                  value={formik.values.productType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Product Type</option>
                  {productTypes.map((type) => (
                    <option key={type._id} value={type._id}>
                      {type.name}
                    </option>
                  ))}
                </Form.Control>
                {formik.touched.productType && formik.errors.productType && (
                  <div className="error">{formik.errors.productType}</div>
                )}
              </Form.Group>
            </Col>
            {/* Additional form fields */}
          </Row>

          {/* Render custom fields */}
          {customFields.length > 0 && customFields.map(field => (
            <Form.Group controlId={`customField-${field.label}`} key={field._id}>
              <Form.Label>{field.label}</Form.Label>
              {field.type === 'text' && (
                <Form.Control
                  type="text"
                  value={customFieldValues[field.label] || ''}
                  onChange={(e) => handleCustomFieldChange(field.label, e.target.value)}
                />
              )}
              {field.type === 'select' && (
                <Form.Control
                  as="select"
                  value={customFieldValues[field.label] || ''}
                  onChange={(e) => handleCustomFieldChange(field.label, e.target.value)}
                >
                  <option value="">Select an option</option>
                  {field.options.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              )}
              {field.type === 'checkbox' && (
                <Form.Check
                  type="checkbox"
                  label={field.label}
                  checked={customFieldValues[field.label] || false}
                  onChange={(e) => handleCustomFieldChange(field.label, e.target.checked)}
                />
              )}
            </Form.Group>
          ))}

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleSubmit}>
          Save Campaign
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateCampaignModal;
