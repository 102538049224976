import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Tabs, Tab, Toast, ToastContainer, Alert, Modal,Table } from 'react-bootstrap';
import '../styles/CreateMessage.css';
import '../styles/TabStyles.css'; // Import the custom tab styles
import { jwtDecode } from 'jwt-decode'; // Check if jwt-decode is the correct import
import { listSignatures, fetchCustomFields, createMessage, updateMessage, postingCountry, getMessage, createEnvelope, updateEnvelope, listEnvelope } from '../services/api'; // Corrected import
import { useUserContext } from '../services/UserProvider';
import { useNavigate, useParams, Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles


const CreateEditMessage = () => {
  const params = useParams();
  const [messageName, setMessageName] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [messageStatus, setMessageStatus] = useState('');
  const [charCount, setCharCount] = useState(0); // Add state for character count
  const { user } = useUserContext();
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [envelopeId, setEnvelopeId] = useState(null); // Add state for envelope ID
  const [envelopeText, setEnvelopeText] = useState(''); // Add state for envelope text
  const [successMessage, setSuccessMessage] = useState(''); // Add state for success message
  const navigate = useNavigate();
  const [robotWritten, setRobotWritten] = useState(true);
  const [robotStyle, setRobotStyle] = useState('Style 1');
  const [penColour, setPenColour] = useState('black');
  const [fontStyle, setFontStyle] = useState('PremiumUltra23');
  const [penSize, setPenSize] = useState('21px'); // State for pen size in px, default to 20px
  const [paperSize, setPaperSize] = useState('A4'); // State for paper size
  const [userCountry, setUserCountry] = useState('');
  const [marginLeftFirst, setMarginLeftFirst] = useState(20);
  const [marginRightFirst, setMarginRightFirst] = useState(20);
  const [marginTopFirst, setMarginTopFirst] = useState(20);
  const [marginLeftSecond, setMarginLeftSecond] = useState(20);
  const [marginRightSecond, setMarginRightSecond] = useState(20);
  const [marginTopSecond, setMarginTopSecond] = useState(20);
  const [customFields, setCustomFields] = useState([]); // State to store custom fields
  const [customFieldValues, setCustomFieldValues] = useState({}); // State to store values for custom fields
  const [showVariableModal, setShowVariableModal] = useState(false);
  const [variableType, setVariableType] = useState('first_name');
  const [lineBreakOption, setLineBreakOption] = useState('none');
  const [showMore, setShowMore] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signatures, setSignatures] = useState([]);
  const [messageContentRight, setMessageContentRight] = useState(''); // Add state for messageContentRight

  const showRightAlignedButton = messageContentRight !== '' || paperSize === 'A4';


  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
  };


  useEffect(() => {
    const fetchUserCountry = async () => {
      if (user) {
        try {
          let response = await postingCountry();
          setUserCountry(response.data.postingCountry);
        } catch (error) {
          console.error("Failed to fetch user's country");
        }
      }
    };

    fetchUserCountry();
  }, [user]);

  useEffect(() => {
    if (user) {
      const decodedToken = jwtDecode(user);
      //setUserId(decodedToken.userId);
      fetchSignatures(decodedToken.userId);
    }
  }, [user]);

  const fetchSignatures = async (userId) => {
    try {
      const response = await listSignatures(userId);
      setSignatures(response.data);
    } catch (error) {
      console.error('Error fetching signatures:', error);
    }
  };

  const handleInsertSignature = (signature) => {
    const placeholder = `{signature: ${signature.signatureName}}`;
    setMessageContent((prevContent) => prevContent + placeholder);
    setShowSignatureModal(false);
  };
  
  useEffect(() => {
    const fetchData = async () => {
      if (params.messageId) {
        try {
          const messageResponse = await getMessage(params.messageId);
          const message = messageResponse.data;
          setEditingMessageId(message._id);
          setMessageName(message.messageName);
          
          // Check if message content is HTML and convert to plain text if needed
          const messageContent = message.messageContent;
          const isHtml = /<\/?[a-z][\s\S]*>/i.test(messageContent);
          setMessageContent(isHtml ? htmlToPlainText(messageContent) : messageContent);
           // Set messageContentRight if available in the response
           setMessageContentRight(message.messageContentRight || '');

          setRobotWritten(message.robotWritten);
          setRobotStyle(message.robotStyle);
          handleRobotStyleChange(message.robotStyle);
          setPenColour(message.penColour);
          setPenSize(message.penSize || '21px'); // Default to 21px if not set
  
          const parseMargins = (margins) => {
            if (!margins) return [20, 20];
            const values = margins.split('|').map(Number);
            return values;
          };
  
          const marginsLeft = parseMargins(message.marginLeft);
          setMarginLeftFirst(marginsLeft[0] || 20);
          setMarginLeftSecond(marginsLeft[1] || 20);
  
          const marginsRight = parseMargins(message.marginRight);
          setMarginRightFirst(marginsRight[0] || 20);
          setMarginRightSecond(marginsRight[1] || 20);
  
          const marginsTop = parseMargins(message.marginTop);
          setMarginTopFirst(marginsTop[0] || 20);
          setMarginTopSecond(marginsTop[1] || 20);
  
          setPaperSize(message.paperSize || 'A4');
          
          // Fetch envelope content
          const envelopeResponse = await listEnvelope(message._id);
          if (envelopeResponse.data) {
            setEnvelopeId(envelopeResponse.data._id);
            setEnvelopeText(envelopeResponse.data.envelopeContent);
          }
        } catch (error) {
          console.error('Error fetching message or envelope:');
        }
      } else {
        // For a new message, set the default envelope text
        setEnvelopeText('{{first_name}} {{last_name}}\n{{companyName}}\n{{addressLine1}}\n{{addressLine2}}\n{{addressLine3}}\n{{cityCounty}}    {{postcode}}');
      }
    };
  
    fetchData();
  }, [params.messageId]);

  // Custom Fields fetc //
 // Custom Fields fetch //
useEffect(() => {
  // Fetch custom fields for messages when entering or editing a message
  fetchCustomFields().then((response) => {
    // Filter custom fields to only show those for 'Messages'
    const messageFields = response.data.filter(field => field.collectionName === 'Messages');
    setCustomFields(messageFields);  // Set only the custom fields related to 'Messages'

    // If editing, fetch the message to get its custom fields
    if (params.messageId) {
      getMessage(params.messageId).then((messageResponse) => {
        const existingCustomFields = {};
        messageResponse.data.customFields.forEach(field => {
          existingCustomFields[field.label] = field.value;
        });
        setCustomFieldValues(existingCustomFields); // Set the existing custom field values
      }).catch((error) => {
        console.error('Error fetching message data');
      });
    }
  }).catch((error) => {
    console.error('Error fetching custom fields');
  });
}, [params.messageId]);



  const handleClear = () => {
    setMessageName('');
    setMessageContent('');
    setRobotWritten(false);
    setRobotStyle('Style 1');
    setFontStyle('PremiumUltra23.ttf');
    setPenColour('Black');
    setPenSize('21px');
    setMarginLeftFirst(20);
    setMarginRightFirst(20);
    setMarginTopFirst(20);
    setMarginLeftSecond(20);
    setMarginRightSecond(20);
    setMarginTopSecond(20);
    setPaperSize('A4');
    setEnvelopeText('{{first_name}} {{last_name}}\n{{companyName}}\n{{addressLine1}}\n{{addressLine2}}\n{{addressLine3}}\n{{cityCounty}}    {{postcode}}');
    setCharCount(0); // Reset character count
  };

  const handleSave = (msgStatus) => {
    const marginLeftValues = `${marginLeftFirst}|${marginLeftSecond}`;
    const marginRightValues = `${marginRightFirst}|${marginRightSecond}`;
    const marginTopValues = `${marginTopFirst}|${marginTopSecond}`;
    const messageData = {
      messageName,
      messageContent,
      robotWritten,
      robotStyle,
      penColour,
      penSize,
      marginLeft: marginLeftValues,
      marginRight: marginRightValues,
      marginTop: marginTopValues,
      paperSize,
      messageStatus: msgStatus,
      userId: jwtDecode(user).userId,
      customFields: Object.keys(customFieldValues).map(label => ({
      label,
      value: customFieldValues[label],
      type: customFields.find(field => field.label === label)?.type
    }))
    };

    if (editingMessageId) {
      updateMessage(editingMessageId, messageData)
        .then(response => {
          const envelopeData = {
            envelopeContent: envelopeText,
            messageId: response.data._id // Ensure the message ID is set correctly
          };
          updateEnvelope(envelopeId, envelopeData)
            .then(envelopeResponse => {
              setSuccessMessage("Message and Envelope have been saved successfully.");
              //setTimeout(() => navigate('/dashboard/messages'), 2000); // Navigate after 2 seconds
            })
            .catch(envelopeError => {
              console.error('Error updating envelope');
            });
        })
        .catch(error => {
          console.error('Error updating message');
        });
    } else {
      createMessage(messageData)
        .then(response => {

          const envelopeData = {
            envelopeContent: envelopeText,
            messageId: response.data.messageId // Ensure the message ID is set correctly
          };
          createEnvelope(envelopeData)
            .then(envelopeResponse => {
              setSuccessMessage("Message and Envelope have been saved successfully.");
              setTimeout(() => navigate('/dashboard/messages'), 2000); // Navigate after 2 seconds
            })
            .catch(envelopeError => {
              console.error('Error creating envelope');
            });
        })
        .catch(error => {
          console.error('Error creating message');
        });
    }
  };

  const variableButtons = [
    { label: 'Title', value: '{{title}}' },
    { label: 'First Name', value: '{{first_name}}' },
    { label: 'Last Name', value: '{{last_name}}' },
    { label: 'Recipients Company Name', value: '{{company_name}}' }
  ];

  const buttonStyle = {
    marginRight: '5px',
    marginBottom: '5px',
    fontSize: 'smaller',
    backgroundColor: 'grey',
    color: 'white',
  };

  const htmlToPlainText = (html) => {
    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
  
    // Replace <br> with newline characters
    tempDiv.querySelectorAll('br').forEach(br => {
      br.replaceWith('\n');
    });
  
    // Replace <p> with newline characters
    tempDiv.querySelectorAll('p').forEach(p => {
      p.replaceWith('\n' + p.innerText + '\n');
    });
  
    // Get the inner text, ensuring new lines are preserved
    return tempDiv.innerText.trim();
  };
  
  
  const insertText = (value) => {
    setMessageContent((prevContent) => {
      const updatedContent = prevContent + value;
      setCharCount(updatedContent.length);
      return updatedContent;
    });
  };

  const handleQuillChange = (content) => {
    setMessageContent(content);
    setCharCount(content.length); // Update character count
  };

  const handleRobotStyleChange = (style) => {
    setRobotStyle(style);
    switch (style) {
      case 'Style 1':
        setFontStyle('PremiumUltra23');
        break;
      case 'Style 2':
        setFontStyle('PremiumUltra27');
        break;
      case 'Style 3':
        setFontStyle('PremiumUltra52');
        break;
      case 'Style 4':
        setFontStyle('PremiumUltra66');
        break;
      case 'Style 5':
        setFontStyle('PremiumUltra11');
        break;
      default:
        setFontStyle('PremiumUltra23.ttf');
        break;
    }
  };

  const handlePenColourChange = (colour) => {
    setPenColour(colour);
    switch (colour) {
      case 'Black':
        setPenColour('Black');
        break;
      case 'Blue':
        setPenColour('Blue');
        break;
      default:
        setPenColour('Black');
        break;
    }
  };

  const handlePenSizeChange = (size) => {
    setPenSize(size);
  };

  const handleMarginChange = (e, setMargin) => {
    setMargin(parseInt(e.target.value, 10));
  };

  const estimateSizeCompatibility = (charCount) => {
    const compatibleSizes = []
    if (charCount <= 1000) {
      compatibleSizes.push("A4")
    }
    if (charCount <= 480) {
      compatibleSizes.push("A5")
    }
    if (charCount <= 250) {
      compatibleSizes.push("A6")
    }
    return compatibleSizes;
  }

  const preprocessMessageContent = (content) => {
    let processedContent = content.replace(/^<div[^>]*>|<\/div>$/g, '');
    processedContent = processedContent.replace(/<p>/g, '').replace(/<\/p>/g, '<br>');
    return processedContent;
  };

  const paperDimensions = {
    A4: { width: '210mm', height: '297mm' },
    'A5 Portrait': { width: '148mm', height: '210mm' },
    'A5 Landscape': { width: '210mm', height: '148mm' },
    'A6 Portrait': { width: '105mm', height: '148mm' },
    'A6 Landscape': { width: '148mm', height: '105mm' },
    'A6 Tent Fold': { width: '148mm', height: '105mm' }, // Same as A5 Portrait
    'A6 Half Fold': { width: '105mm', height: '148mm' }  // Same as A5 Landscape
  };

 
  const contentStyleFirstPage = {
    fontFamily: fontStyle,
    fontSize: penSize,
    color: penColour,
    whiteSpace: 'pre-wrap',
    maxWidth: `${paperDimensions[paperSize].width}`,
    marginLeft: `${marginLeftFirst}mm`,
    marginRight: `${marginRightFirst}mm`,
    marginTop: `${marginTopFirst}mm`,
    //overflow: 'hidden', // Ensure content stays within the page
  };

  const contentStyleSecondPage = {
    fontFamily: fontStyle,
    fontSize: penSize,
    color: penColour,
    whiteSpace: 'pre-wrap',
    maxWidth: `${paperDimensions[paperSize].width}`,
    marginLeft: `${marginLeftSecond}mm`,
    marginRight: `${marginRightSecond}mm`,
    marginTop: `${marginTopSecond}mm`,
    overflow: 'hidden', // Ensure content stays within the page
  };

  const envelopeButtons = [
    { label: 'First Name', value: '{{first_name}}' },
    { label: 'Last Name', value: '{{last_name}}' },
    { label: 'Company Name', value: '{{companyName}}' },
    { label: 'Address Line 1', value: '{{addressLine1}}' },
    { label: 'Address Line 2', value: '{{addressLine2}}' },
    { label: 'Address Line 3', value: '{{addressLine3}}' },
    { label: 'City', value: '{{cityCounty}}' },
    { label: 'Postcode', value: '{{postcode}}' },
  ];

  const mockData = {
    first_name: 'Mary',
    last_name: 'Jane',
    companyName: 'Acme Inc',
    addressLine1: 'Floor 4',
    addressLine2: 'City Towers',
    addressLine3: '123 London Road',
    cityCounty: 'London',
    postcode: 'E1 2CY',
  };

  const insertEnvelopeText = (value) => {
    setEnvelopeText((prevText) => prevText + ' ' + value);
  };

  const preprocessEnvelopeContent = (content) => {
    let processedContent = content;
    for (const key in mockData) {
      const regex = new RegExp(`{{${key}}}`, 'g');
      processedContent = processedContent.replace(regex, mockData[key]);
    }
    processedContent = processedContent.replace(/ /g, '&nbsp;');
    processedContent = processedContent.replace(/\n/g, '<br />');
    return processedContent;
  };

  const renderFoldLine = () => {
    if (paperSize === 'A6 Half Fold' || paperSize === 'A6 Tent Fold') {
      return (
        <div
          style={{
            position: 'absolute',
            width: paperSize === 'A6 Tent Fold' ? '100%' : '2px',
            height: paperSize === 'A6 Tent Fold' ? '2px' : '100%',
            backgroundColor: 'black',
            top: paperSize === 'A6 Tent Fold' ? '50%' : '0',
            left: paperSize === 'A6 Tent Fold' ? '0' : '50%',
            transform: paperSize === 'A6 Tent Fold' ? 'translateY(-1px)' : 'translateX(-50%)',
            zIndex: 1
          }}
        />
      );
    }
    return null;
  };

  const getPageStyle = () => {
    const dimensions = paperDimensions[paperSize];
    const isHalfFold = paperSize === 'A6 Half Fold';
    const isTentFold = paperSize === 'A6 Tent Fold';
    return {
      ...dimensions,
      background: 'white',
      display: 'block',
      margin: '0 auto', // Center the page horizontally
      marginBottom: isHalfFold || isTentFold ? '0' : '0.5cm',
      boxShadow: '0 0 0.5cm rgba(0,0,0,0.5)',
      overflow: 'auto',
      padding: '0mm',
      borderRadius: '5px',
      position: 'relative',
    };
  };
  
  const renderContentWithPageBreaks = () => {
    const parts = messageContent.split('<!--PAGEBREAK--!>');
    const isHalfFold = paperSize === 'A6 Half Fold';
    const isTentFold = paperSize === 'A6 Tent Fold';
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: isHalfFold ? 'row' : 'column',
      gap: isHalfFold ? '0' : '1cm'
    };
  
    return (
      <div style={containerStyle}>
        <div style={getPageStyle()}>
          <div
            style={contentStyleFirstPage}
            dangerouslySetInnerHTML={{
              __html: preprocessMessageContent(parts[0] || ''),
            }}
          />
        </div>
        {parts.length > 1 && (
          <div style={getPageStyle()}>
            <div
              style={contentStyleSecondPage}
              dangerouslySetInnerHTML={{
                __html: preprocessMessageContent(parts[1]),
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const handleVariableInsert = () => {
    let variableText = `{{${variableType}}}`;

    if (lineBreakOption === 'before') {
      variableText = `{{[[\\r]]${variableType}}}`;
    } else if (lineBreakOption === 'after') {
      variableText = `{{${variableType}[[\\r]]}}`;
    } else if (lineBreakOption === 'both') {
      variableText = `{{[[\\r]]${variableType}[[\\r]]}}`;
    }

    insertText(variableText);
    setShowVariableModal(false);
  };

  
  
  return (
    <Container fluid>
      <Row>
        <h1>{editingMessageId ? "Edit Message" : "Create New Message"}</h1>
      </Row>
      <Row>
        {/* <Alert variant="warning">
          Please note we've recently made an upgrade that may impact your message formatting. Spaces may be removed or duplicated. Please review your message.
        </Alert> */}
        <p className="text-muted">Type your personalised message to you'll use in your campaign. Simply write the message, add any existing variables or write custom ones that will be in your recipient file.</p>
       <Col>
  <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
    <Button variant="secondary" onClick={handleClear}>Clear</Button>
    <Button variant="warning" onClick={() => handleSave("Draft")}>Save as Draft</Button>
    <Button variant="primary" onClick={() => handleSave("Published")}>Save</Button>
    
    {/* Conditionally render the "Add/Edit A4 Right Aligned Text" link */}
    {editingMessageId && (
      <Link to={`/dashboard/messages/a4editor/${editingMessageId}`} style={{ display: 'inline' }}>
        <Button variant="primary">
          Add/Edit A4 Right Aligned Text
        </Button>
      </Link>
    )}

  </div>
</Col>

      </Row>
      <Tabs defaultActiveKey="messageInfo" id="message-tabs" className="custom-tabs">
        <Tab eventKey="messageInfo" title="Message Information">
          <Row className="shadow-sm border rounded mt-3">
            <Col className="p-3">
              <Form>
                <Form.Group className="mb-3 mt-4">
                  <Form.Label>Message Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={messageName}
                    onChange={(e) => setMessageName(e.target.value)}
                    placeholder="Enter message name"
                  />
                </Form.Group>
                
                <div>
                <Alert variant="info" className="mt-3">
        <p className="text-muted mb-0">
          Custom Variables work by replacing the recipient data provided. If you include a variable in your message and it isn't provided in your recipient data, it will be ignored.
          <br />
          <Button variant="link" onClick={toggleShowMore} className="p-0">
            {showMore ? 'Show less' : 'Show more'}
          </Button>
        </p>
        {showMore && (
          <div className="mt-2">
            <p className="text-muted mb-0">
              You can provide a line break before, after, or both by using <code>[[\r]]</code> before the variable name.
              For example, <code>"Dear &#123;&#123;first_name[[\r]]&#125;&#125;</code> here is an example" will be converted to:
              <br />
              <em>"Dear John<br/> here is an example."</em>
              <br /><br />
              Use our variable editor to insert them quickly, or type them manually.
            </p>
          </div>
        )}
      </Alert>
                <Button variant="primary" onClick={() => setShowVariableModal(true)}>
        Insert Variable with Line Breaks
      </Button> { ' '}
                  {variableButtons.map((button, index) => (
                    <Button
                      key={index}
                      size="sm"
                      style={buttonStyle}
                      onClick={() => insertText(button.value)}>
                      {button.label}
                    </Button>
                  ))}
                    <Button variant="primary" onClick={() => setShowSignatureModal(true)}>
            Insert Signature
          </Button>
                </div>
                <Modal show={showVariableModal} onHide={() => setShowVariableModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Insert Variable with Line Breaks</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group controlId="variableType">
        <Form.Label>Enter Variable Name</Form.Label>
        <Form.Control
          type="text"
          value={variableType}
          onChange={(e) => setVariableType(e.target.value)}
          placeholder="Enter the variable name (e.g., first_name)"
        />
      </Form.Group>

      <Form.Group controlId="lineBreakOption" className="mt-3">
        <Form.Label>Line Breaks</Form.Label>
        <Form.Control
          as="select"
          value={lineBreakOption}
          onChange={(e) => setLineBreakOption(e.target.value)}
        >
          <option value="none">None</option>
          <option value="before">Before</option>
          <option value="after">After</option>
          <option value="both">Before & After</option>
        </Form.Control>
      </Form.Group>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowVariableModal(false)}>
      Cancel
    </Button>
    <Button variant="primary" onClick={handleVariableInsert}>
      Insert Variable
    </Button>
  </Modal.Footer>
</Modal>

                <div>
        

                </div>
                {['A6 Half Fold', 'A6 Tent Fold'].includes(paperSize) && (
                  <Button
                    size="sm"
                    style={{ color: 'white', display: 'block', marginTop: '10px' }}
                    onClick={() => insertText('<!--PAGEBREAK--!>')}
                  >
                    Insert Page Break
                  </Button>
                )}{' '}<div><p><b>To write your message for a greeting card click Insert Page Break. Text before the page break will be the top page for tent hold and first page for half fold. Text after the page break is the second page.</b></p></div>
                {/* <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={6}
                    value={messageContent}
                    onChange={(e) => handleQuillChange(e.target.value)}
                    placeholder={"Hi {{first_name}}, I'm a compelling message to help drive your business forward."}
                  />
                  <div className="char-count">
                    Character count: {charCount} | Estimated Compatibility: {estimateSizeCompatibility(charCount).map(s => `${s} `)}
                  </div>
                </Form.Group>
                 */}
             
              <Form.Group className="mb-3">
                <Form.Label>Message Content</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={6}
                    value={messageContent}
                    onChange={(e) => handleQuillChange(e.target.value)}
                    placeholder={"Hi {{first_name}}, I'm a compelling message to help drive your business forward."}
                  />
                <div className="char-count">
                  Character count: {charCount} | Estimated Compatibility: {estimateSizeCompatibility(charCount).map(s => `${s} `)}
                </div>
              </Form.Group>

              </Form>
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="envelopeFormatting" title="Envelope Formatting">
           <Row className="shadow-sm border rounded p-3 mt-4">
              <Col className="p-3">
                <h3>Envelope Formatting</h3>
                <p className="text-muted">
                  Format the envelope to include how you'd like it to be displayed and if you include any additional variables from your recipient data, enter them in.
                </p>
                <div>
                  {envelopeButtons.map((button, index) => (
                    <Button
                      key={index}
                      size="sm"
                      style={{ marginRight: '5px', marginBottom: '5px', fontSize: 'smaller', backgroundColor: 'grey', color: 'white' }}
                      onClick={() => insertEnvelopeText(button.value)}
                    >
                      {button.label}
                    </Button>
                  ))}
                </div>
                <Form.Group className="mb-3 mt-4">
                  <Form.Control
                    as="textarea"
                    rows={6}
                    value={envelopeText}
                    onChange={(e) => setEnvelopeText(e.target.value)}
                    placeholder="{{first_name}} {{last_name}}\n{{companyName}}\n{{addressLine1}}\n{{addressLine2}}\n{{addressLine3}}\n{{cityCounty}} {{postcode}}"
                  />
                </Form.Group>
              </Col>
              <Col className="p-3">
                <h3>Preview</h3>
                <div
                  style={{ fontFamily: fontStyle, fontSize: '25px', color: penColour, whiteSpace: 'pre-wrap', margin: '40px' }}
                  dangerouslySetInnerHTML={{ __html: preprocessEnvelopeContent(envelopeText) }}
                />
              </Col>
            </Row>
        </Tab>
        <Tab eventKey="customFields" title="Custom Fields">
  <Row className="shadow-sm border rounded p-3 mt-4">
    <Col className="p-3">
      <h3>Custom Fields</h3>
      <Row>
      <Alert variant="info" className="mt-3">
        <p className="text-muted mb-0">
          Custom fields are used for storing values in the database that are required for API or reporting purposes. Such as clientId or version etc. Custom fields are managed in Settings.
          </p>
      </Alert>
      </Row>
      <Form>
        {customFields.length > 0 ? (
          <Row> {/* Add Row to wrap custom fields */}
            {customFields.map(field => (
              <Col md={6} key={field.label}> {/* Each field takes half width (6 columns) */}
                <Form.Group controlId={`customField-${field.label}`} className="mb-3">
                  <Form.Label>{field.label}</Form.Label>

                  {/* Handle text fields */}
                  {field.type === 'text' && (
                    <Form.Control
                      type="text"
                      name={field.label}
                      value={customFieldValues[field.label] || ''}
                      onChange={(e) =>
                        setCustomFieldValues(prevValues => ({
                          ...prevValues,
                          [field.label]: e.target.value
                        }))
                      }
                    />
                  )}

                  {/* Handle select fields */}
                  {field.type === 'select' && (
                    <Form.Control
                      as="select"
                      name={field.label}
                      value={customFieldValues[field.label] || ''}
                      onChange={(e) =>
                        setCustomFieldValues(prevValues => ({
                          ...prevValues,
                          [field.label]: e.target.value
                        }))
                      }
                    >
                      <option value="">Select an option</option>
                      {field.options.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  )}

                  {/* Handle checkbox fields */}
                  {field.type === 'checkbox' && (
                    <Form.Check
                      type="checkbox"
                      name={field.label}
                      label={field.label}
                      checked={customFieldValues[field.label] || false}
                      onChange={(e) =>
                        setCustomFieldValues(prevValues => ({
                          ...prevValues,
                          [field.label]: e.target.checked
                        }))
                      }
                    />
                  )}
                </Form.Group>
              </Col>
            ))}
          </Row>
        ) : (
          <p>No custom fields available.</p>
        )}
      </Form>
    </Col>
  </Row>
</Tab>


      </Tabs>
      <Row className="shadow-sm border rounded mt-3">
      </Row>
      <Row className="shadow-sm border rounded mt-3">
        {userCountry !== "GB" && (
          <Form.Group className="mb-3">
            <Form.Label>Handwriting Style</Form.Label>
            <Form.Control
              as="select"
              value={robotWritten ? 'Pen-to-paper (robot written)' : 'Handwritten'}
              onChange={(e) => setRobotWritten(e.target.value === 'Pen-to-paper (robot written)')}
            >
              <option value="Handwritten">Handwritten</option>
              <option value="Pen-to-paper (robot written)">Pen-to-paper (robot written)</option>
            </Form.Control>
          </Form.Group>
        )}

        {robotWritten && (
          <Form.Group as={Row} className="mb-3">
            <Col>
              <Form.Group className="mb-3 mt-4">
                <Form.Label style={{ fontWeight: 'bold' }}>Select Paper Size</Form.Label>
                <Form.Control
                  as="select"
                  value={paperSize}
                  onChange={(e) => setPaperSize(e.target.value)}
                >
                  <option value="A4">A4 Letter</option>
                  <option value="A5 Portrait">A5 Card - Portrait</option>
                  <option value="A5 Landscape">A5 Card - Landscape</option>
                  <option value="A6 Portrait">A6 Card - Portrait</option>
                  <option value="A6 Landscape">A6 Card - Landscape</option>
                  <option value="A6 Half Fold">A6 Greeting Card - Half Fold</option>
                  <option value="A6 Tent Fold">A6 Greeting Card - Tent Fold</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 mt-4">
                <Form.Label style={{ fontWeight: 'bold' }}>Writing Style</Form.Label>
                <Form.Control
                  as="select"
                  value={robotStyle}
                  onChange={(e) => handleRobotStyleChange(e.target.value)}
                >
                  <option value="Style 1">Style 1</option>
                  <option value="Style 2">Style 2</option>
                  <option value="Style 3">Style 3</option>
                  <option value="Style 4">Style 4</option>
                  <option value="Style 5">Style 5</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 mt-4">
                <Form.Label style={{ fontWeight: 'bold' }}>Pen Colour</Form.Label>
                <Form.Control
                  as="select"
                  value={penColour}
                  onChange={(e) => handlePenColourChange(e.target.value)}
                >
                  <option value="Black">Black</option>
                  <option value="Blue">Blue</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 mt-4">
                <Form.Label style={{ fontWeight: 'bold' }}>Handwriting Size</Form.Label>
                <Form.Control
                  as="select"
                  value={penSize}
                  onChange={(e) => handlePenSizeChange(e.target.value)}
                >
                  {Array.from({ length: 15 }, (_, i) => i + 16).map(size => (
                    <option key={size} value={`${size}px`}>{size}px</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Form.Group>
        )}

        {robotWritten && (
          <Col className="p-3">
            <Form.Group as={Row} className="mb-3">
              <Col>
                <Form.Label style={{ fontWeight: 'bold' }}>First Page Margin Left - {marginLeftFirst}mm</Form.Label>
                <Form.Control
                  type="range"
                  min="1"
                  max="100"
                  value={marginLeftFirst}
                  onChange={(e) => handleMarginChange(e, setMarginLeftFirst)}
                />
              </Col>
              <Col>
                <Form.Label style={{ fontWeight: 'bold' }}>First Page Margin Right - {marginRightFirst}mm</Form.Label>
                <Form.Control
                  type="range"
                  min="1"
                  max="100"
                  value={marginRightFirst}
                  onChange={(e) => handleMarginChange(e, setMarginRightFirst)}
                />
              </Col>
              <Col>
                <Form.Label style={{ fontWeight: 'bold' }}>First Page Margin Top - {marginTopFirst}mm</Form.Label>
                <Form.Control
                  type="range"
                  min="1"
                  max="100"
                  value={marginTopFirst}
                  onChange={(e) => handleMarginChange(e, setMarginTopFirst)}
                />
              </Col>
            </Form.Group>
            {['A6 Half Fold', 'A6 Tent Fold'].includes(paperSize) && (
              <Form.Group as={Row} className="mb-3">
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Second Page Margin Left - {marginLeftSecond}mm</Form.Label>
                  <Form.Control
                    type="range"
                    min="1"
                    max="100"
                    value={marginLeftSecond}
                    onChange={(e) => handleMarginChange(e, setMarginLeftSecond)}
                  />
                </Col>
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Second Page Margin Right - {marginRightSecond}mm</Form.Label>
                  <Form.Control
                    type="range"
                    min="1"
                    max="100"
                    value={marginRightSecond}
                    onChange={(e) => handleMarginChange(e, setMarginRightSecond)}
                  />
                </Col>
                <Col>
                  <Form.Label style={{ fontWeight: 'bold' }}>Second Page Margin Top - {marginTopSecond}mm</Form.Label>
                  <Form.Control
                    type="range"
                    min="1"
                    max="100"
                    value={marginTopSecond}
                    onChange={(e) => handleMarginChange(e, setMarginTopSecond)}
                  />
                </Col>
              </Form.Group>
            )}
          </Col>
        )}
        
        <p className="text-muted">The preview is an example representation of your text. There may be variances when writing your letter due to the handwriting effects that are incorporated in the writing that may change the positioning of the words.</p>

        {renderContentWithPageBreaks()}
      </Row>

      {successMessage && (
        <ToastContainer position="middle-center" className='p-3' style={{ zIndex: 1 }}>
          <Toast onClose={() => setSuccessMessage("")} show={successMessage} delay={3000} autohide>
            <Toast.Header>
              <strong className='me-auto'>Penned</strong>
            </Toast.Header>
            <Toast.Body>{successMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}

        {/* Modal for selecting signature */}
        <Modal 
  show={showSignatureModal} 
  onHide={() => setShowSignatureModal(false)} 
  dialogClassName="custom-modal-width"
  centered
>

  <Modal.Dialog style={{ maxWidth: '500px', width: '100%' }}>        <Modal.Header closeButton>
          <Modal.Title>Select Signature</Modal.Title>
        </Modal.Header>
       

        <Modal.Body>
  {signatures.length === 0 ? (
    <p>No signatures available. Please create a signature first.</p>
  ) : (
    <>
      <p>
        <strong>
          Note: Signatures will only be inserted at a fixed position. If your variable text impacts the number of lines (e.g., pushes text down a line), it will not be written.
        </strong>
      </p>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Signature Name</th>
            <th>Preview</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {signatures.map((signature) => (
            <tr key={signature._id}>
              <td>{signature.signatureName}</td>
              <td>
                <div
                  style={{
                    maxWidth: '200px',
                    maxHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflow: 'hidden',
                    border: '1px solid #ddd',
                    backgroundColor: '#f8f9fa',
                  }}
                >
                  <div
                    style={{
                      width: '80%',
                      height: 'auto',
                    }}
                    dangerouslySetInnerHTML={{ __html: signature.rawSig }}
                  />
                </div>
              </td>
              <td>
                <Button variant="primary" onClick={() => handleInsertSignature(signature)}>
                  Insert
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )}
</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSignatureModal(false)}>
            Close
          </Button>
        </Modal.Footer>
        </Modal.Dialog>

      </Modal>
    </Container>



  );
};

export default CreateEditMessage;
