import React, { createContext, useContext, useState } from 'react';
import authService from './authService' // Import your authService
import { log, error} from './logger';

// Create the UserContext
const UserContext = createContext();

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  // Use the JWT token from local storage to determine if user is logged in
  const [user, setUser] = useState(localStorage.getItem('jwtToken') || null);

  const login = async (email, password) => {
    try {
      const response = await authService.signin(email, password);
      //response contains JWT Token.
      log("Signin response data: ", response.data);
      const { token, refreshToken }  = response.data;
      log("Login token: ", token);
      log("Refresh token: ", refreshToken );
      // Store the JWT token in local storage
      localStorage.setItem('jwtToken', token);
      localStorage.setItem('refreshToken', refreshToken);
      // Update user state
      setUser(token);

    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await authService.signout();

      // Remove the JWT token from local storage
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('refreshToken');

      // Update user state
      setUser(null);

    } catch (error) {
      console.error('Error during logout:', error);
    }
  };
  const updateUserToken = (token) => {
    localStorage.setItem('jwtToken', token);
    setUser(token); // Update the user state with the JWT token
};


const refreshAuthToken = async () => {
  try {
    const response = await authService.refreshToken();
    const { token, refreshToken } = response.data;

    localStorage.setItem('jwtToken', token);
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }

    setUser(token);
    return true;
  } catch (error) {
    console.error('Error refreshing token:', error);
    logout();
    return false;
  }
};

  const contextValue = {
    user,
    setUser,
    login,
    logout,
    refreshAuthToken,
    updateUserToken, // Add this function to the context

  };



  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
